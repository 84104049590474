<pe-modal-header [modalInstance]="modalInstance"
    [title]="translate('Agito.Hilti.Profis3.Navigation.SmartAnchor.AdvancedInputs.Title')"
    [closeButtonId]="'asad-optimize-modal-close-button'">
</pe-modal-header>
<div class="container-select-drop-down">
    <!-- Design Method -->
    <pe-dropdown *ngIf="isAsadAndACI" class="select-drop-down" [id]="projectDesignMethodDropdown.id"
        [notSelectedText]="projectDesignMethodDropdown.notSelectedText" [items]="projectDesignMethodDropdown.items"
        [selectedValue]="projectDesignMethodDropdown.selectedValue"
        (selectedValueChange)="onDesignMethodDropdownSelectedValueChange($any($event).detail)">
    </pe-dropdown>
</div>
<div class="area">
    <div class="area-header">
        <p class="area-header-text"
            [innerHtml]="translate('Agito.Hilti.Profis3.Navigation.TabSmartAnchor.RegionOptimization')"></p>
        <div class="line"></div>
    </div>

    <div class="utilization">
        <div class="slider">
            <pe-slider [title]="translate('Agito.Hilti.Profis3.SmartBasePlate.FilterPanel.Utilization')"
                [value]="maxUtilization" [sliderType]="slider" [isAdvanceInput]="true" [options]="utilizationOptions"
                (valueChange)="utilizationChange($any($event).detail)"></pe-slider>
        </div>
    </div>

    <div class="container-wrapper">
        <div class="container">
            <p class="container-header"
                [innerHtml]="translate('Agito.Hilti.Profis3.Navigation.SmartAnchor.UtilizationOptions.ProfilePositionBasePlate.Title')">
            </p>
            <div class="radio">
                <label for="centered" (click)="profilePosition=1">
                    <div class="radio-button">
                        <input type="radio" id="centered" name="profile" [value]="1" [(ngModel)]="profilePosition">
                        <span></span>
                    </div>
                    <p [innerHtml]="translate('Agito.Hilti.Profis3.CodeList.ProfilePositionsAsad.Center')"></p>
                </label>
            </div>
            <div class="radio">
                <label for="notCentered" (click)="profilePosition=2">
                    <div class="radio-button">
                        <input type="radio" id="notCentered" name="profile" [value]="2" [(ngModel)]="profilePosition">
                        <span></span>
                    </div>
                    <p [innerHtml]="translate('Agito.Hilti.Profis3.CodeList.ProfilePositionsAsad.OffCentered')"></p>
                </label>
            </div>
        </div>

        <div class="container padding">
            <p class="container-header"
                [innerHtml]="translate('Agito.Hilti.Profis3.Navigation.SmartAnchor.UtilizationOptions.AnchorPosition.Title')">
            </p>
            <div class="radio">
                <label for="outside" (click)="anchorPosition=2">
                    <div class="radio-button">
                        <input type="radio" id="outside" name="anchor" [value]="2" [(ngModel)]="anchorPosition">
                        <span></span>
                    </div>
                    <p [innerHtml]="translate('Agito.Hilti.Profis3.CodeList.AnchorPositionsAsad.OutsideProfile')"></p>
                </label>
            </div>
            <div class="radio">
                <label for="inside" (click)="anchorPosition=1">
                    <div class="radio-button">
                        <input type="radio" id="inside" name="anchor" [value]="1" [(ngModel)]="anchorPosition">
                        <span></span>
                    </div>
                    <p [innerHtml]="translate('Agito.Hilti.Profis3.CodeList.AnchorPositionsAsad.InsideProfile')"></p>
                </label>
            </div>
        </div>

        <div class="container padding">
            <p class="container-header" [innerHtml]="translate('Agito.Hilti.Profis3.AdvancedInputs.Optimize')"></p>
            <div class="radio">
                <label for="dimension" (click)="optimizeType=false">
                    <div class="radio-button">
                        <input type="radio" id="dimension" name="optimizeType" [value]="false"
                            [(ngModel)]="optimizeType">
                        <span></span>
                    </div>
                    <p [innerHtml]="translate('Agito.Hilti.Profis3.AdvancedInputs.Optimize.Dimension')"></p>
                </label>
            </div>
            <div class="radio">
                <label for="depth" (click)="optimizeType=true">
                    <div class="radio-button">
                        <input type="radio" id="depth" name="optimizeType" [value]="true" [(ngModel)]="optimizeType">
                        <span></span>
                    </div>
                    <p [innerHtml]="translate('Agito.Hilti.Profis3.AdvancedInputs.Optimize.Embedment')"></p>
                </label>
            </div>
        </div>
    </div>
</div>
<div class="area">
    <div class="area-header">
        <p class="area-header-text"
            [innerHtml]="translate('Agito.Hilti.Profis3.Navigation.TabSmartAnchor.RegionConstructionOptions')"></p>
        <div class="line"></div>
    </div>
    <div class="container-wrapper">
        <div class="container height">
            <p class="container-header" [innerHtml]="translate('Agito.Hilti.Profis3.Windloads.Installation')"></p>
            <p class="select-header"
                [innerHtml]="translate('Agito.Hilti.Profis3.Navigation.TabBasePlate.RegionStandOff.ControlStandoffType')">
            </p>
            <div class="standoff-container">
                <button class="standoff-button" type="button" (click)="standOffTypeButton()">
                    <div class="standoff-button-content">
                        <div class="standoff-button-image">
                            <div class="image-wrapper">
                                <span class="image pe-ui-pe-sprite sprite-responsive pe-ui-pe-sprite-standoff-none"
                                    *ngIf="standOffType==1"></span>
                                <span class="image pe-ui-pe-sprite sprite-responsive pe-ui-pe-sprite-standoff"
                                    *ngIf="standOffType==2"></span>
                                <span class="image pe-ui-pe-sprite sprite-responsive pe-ui-pe-sprite-standoff-clamping"
                                    *ngIf="standOffType==3"></span>
                                <span class="image pe-ui-pe-sprite sprite-responsive pe-ui-pe-sprite-standoff-grouting"
                                    *ngIf="standOffType==4"></span>
                            </div>
                        </div>
                        <div class="standoff-button-seperator"></div>
                        <div class="standoff-button-text">
                            <p class="name" *ngIf="standOffType==1"
                                [innerHtml]="translate('Agito.Hilti.Profis3.CodeList.StandOffEntity.None')"></p>
                            <p class="name" *ngIf="standOffType==2"
                                [innerHtml]="translate('Agito.Hilti.Profis3.CodeList.StandOffEntity.WithoutClamping')">
                            </p>
                            <p class="name" *ngIf="standOffType==3"
                                [innerHtml]="translate('Agito.Hilti.Profis3.CodeList.StandOffEntity.WithClamping')"></p>
                            <p class="name" *ngIf="standOffType==4"
                                [innerHtml]="translate('Agito.Hilti.Profis3.CodeList.StandOffEntity.WithGrouting')"></p>
                        </div>
                        <span class="pe-ui-pe-sprite pe-ui-pe-sprite-arrow-right button-rich-caret"></span>
                    </div>
                </button>
                <div class="selects-wrapper" *ngIf="standOffType!=1">
                    <div class="select-50 space">
                        <p class="select-header"
                            [innerHtml]="translate('Agito.Hilti.Profis3.Navigation.TabBasePlate.RegionStandOff.ControlDistance')">
                        </p>
                        <pe-numeric-text-box [id]="'additional-filter-standOff-distance'" [value]="standOffDistance"
                            (valueChange)="standOffDistance=$any($event).detail" [unit]="lengthUnit" [type]="'text'">
                        </pe-numeric-text-box>
                    </div>
                    <div class="select-50 space">
                        <p class="select-header"
                            [innerHtml]="translate('Agito.Hilti.Profis3.Navigation.TabBasePlate.RegionStandOff.ControlRotationalRestraint')">
                        </p>
                        <pe-numeric-text-box [id]="'additional-filter-standOff-restraint'" [value]="standOffRestraint"
                            (valueChange)="standOffRestraint=$any($event).detail" [type]="'text'">
                        </pe-numeric-text-box>
                    </div>
                </div>
                <div *ngIf="standOffType==4">
                    <div class="selects-wrapper" *ngIf="this.groutTypes && this.groutTypes.length > 0">
                        <div class="select-100 space">
                            <pe-dropdown class="control" [id]="groutTypesDropdown.id" [items]="groutTypesDropdown.items"
                                [notSelectedText]="groutTypesDropdown.notSelectedText"
                                [selectedValue]="groutTypesDropdown.selectedValue"
                                (selectedValueChange)="selectGroutType($any($event).detail)">
                            </pe-dropdown>
                        </div>
                    </div>
                    <div class="selects-wrapper"  *ngIf="standOffCompresiveStrenghtVisible">
                        <p class="select-header space"
                            [innerHtml]="translate('Agito.Hilti.Profis3.Navigation.TabBasePlate.RegionStandOff.ControlGroutCompressiveStrength')">
                        </p>
                    </div>
                    <div class="selects-wrapper compressed" *ngIf="standOffCompresiveStrenghtVisible">
                        <div class="select-50">
                            <pe-numeric-text-box [id]="'additional-filter-standOff-compressive-strength'"
                                [value]="standOffCompresiveStrenght"
                                (valueChange)="standOffCompresiveStrenght=$any($event).detail"
                                [unit]="compressiveStrengthUnit" [disabled]="standOffGroutingType == 33"
                                [type]="'text'">
                            </pe-numeric-text-box>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container padding">
            <p class="container-header" [innerHtml]="translate('Agito.Hilti.Profis3.AdvancedInputs.Material')"></p>
            <div class="container-top">
                <pe-multiselect-dropdown [id]="constructionMaterialOptionsDdl.id"
                    [title]="constructionMaterialOptionsDdl.title"
                    [notSelectedText]="constructionMaterialOptionsDdl.notSelectedText"
                    [items]="constructionMaterialOptionsDdl.items"
                    [selectedValue]="constructionMaterialOptionsDdl.selectedValue"
                    (selectedValuesChange)="constructionMaterialSelectionChange($any($event).detail)"
                    [validators]="constructionMaterialOptionsDdl.validators"
                    [selectedValues]="constructionMaterialOptionsDdl.selectedValues">
                </pe-multiselect-dropdown>
            </div>
            <div class="temperature">
                <p class="container-header"
                    [innerHtml]="translate('Agito.Hilti.Profis3.Navigation.SmartAnchor.Text.Temprature.Title')"></p>
                <div class="selects-wrapper">
                    <div class="select-50">
                        <p class="select-header"
                            [innerHtml]="translate('Agito.Hilti.Profis3.Navigation.TabBaseMaterial.RegionTemperature.ControlShortTerm')">
                        </p>
                        <pe-numeric-text-box [id]="'additional-filter-short-term-temperatue'"
                            [value]="shortTermTemperatue" (valueChange)="shortTermTemperatue=$any($event).detail"
                            [unit]="temperatureUnit" [type]="'text'">
                        </pe-numeric-text-box>
                    </div>
                    <div class="select-50">
                        <p class="select-header"
                            [innerHtml]="translate('Agito.Hilti.Profis3.Navigation.TabBaseMaterial.RegionTemperature.ControlLongTerm')">
                        </p>
                        <pe-numeric-text-box [id]="'additional-filter-long-term-temperatue'"
                            [value]="longTermTemperatue" (valueChange)="longTermTemperatue=$any($event).detail"
                            [unit]="temperatureUnit" [type]="'text'">
                        </pe-numeric-text-box>
                    </div>
                </div>
            </div>
            <div class="thickness">
                <p class="container-header"
                    [innerHtml]="translate('Agito.Hilti.Profis3.AdvancedInputs.BaseplateThickness')"></p>
                <div class="selects-wrapper">
                    <div class="select-100">
                        <pe-numeric-text-box [id]="'additional-filter-baseplate-thickness'" [value]="baseplateThickness"
                            (valueChange)="baseplateThickness=$any($event).detail" [unit]="lengthUnit" [type]="'text'">
                        </pe-numeric-text-box>
                    </div>
                </div>
            </div>
        </div>

        <div class="container padding">
            <div *ngIf="!isACI">
                <p class="container-header" [innerHtml]="translate('Agito.Hilti.Profis3.Legend.Concrete')"></p>
                <div class="select-100 container-top">
                    <p class="select-header"
                        [innerHtml]="translate('Agito.Hilti.Profis3.CodeList.DesignTypeEntity.Concrete')"></p>
                    <button class="dropdown-button" type="button" (click)="concreteTypeOpen = !concreteTypeOpen">
                        <div class="dropdown-button-container">
                            <div class="button-item">
                                <p class="button-item-text" *ngIf="concreteType==1"
                                    [innerHtml]="translate('Agito.Hilti.Profis3.CodeList.ConcreteReinforcementEntity.None')">
                                </p>
                                <p class="button-item-text" *ngIf="concreteType==2"
                                    [innerHtml]="translate('Agito.Hilti.Profis3.CodeList.ConcreteReinforcementEntity.Wide')">
                                </p>
                                <p class="button-item-text" *ngIf="concreteType==3"
                                    [innerHtml]="translate('Agito.Hilti.Profis3.CodeList.ConcreteReinforcementEntity.Dense')">
                                </p>
                            </div>
                            <div class="caret-container">
                                <div class="caret" [ngClass]="holeTypeOpen ? 'caret-up': 'caret-down'"></div>
                            </div>
                        </div>
                    </button>
                    <div class="dropdown-items" [ngClass]="{'opened': concreteTypeOpen}">
                        <button class="dropdown-item" type="button" [ngClass]="{'selected': (concreteType==1)}"
                            (click)="setConcreteType(1)">
                            <div class="item">
                                <p class="item-text"
                                    [innerHtml]="translate('Agito.Hilti.Profis3.CodeList.ConcreteReinforcementEntity.None')">
                                </p>
                            </div>
                        </button>
                        <button class="dropdown-item" type="button" [ngClass]="{'selected': (concreteType==2)}"
                            (click)="setConcreteType(2)">
                            <div class="item">
                                <p class="item-text"
                                    [innerHtml]="translate('Agito.Hilti.Profis3.CodeList.ConcreteReinforcementEntity.Wide')">
                                </p>
                            </div>
                        </button>
                        <button class="dropdown-item" type="button" [ngClass]="{'selected': (concreteType==3)}"
                            (click)="setConcreteType(3)">
                            <div class="item">
                                <p class="item-text"
                                    [innerHtml]="translate('Agito.Hilti.Profis3.CodeList.ConcreteReinforcementEntity.Dense')">
                                </p>
                            </div>
                        </button>
                    </div>
                </div>
                <div class="select-100 space">
                    <p class="select-header"
                        [innerHtml]="translate('Agito.Hilti.Profis3.Navigation.TabBaseMaterial.RegionReinforcement.ControlEdgeReinforcement')">
                    </p>
                    <button class="dropdown-button" type="button" (click)="edgeTypeOpen = !edgeTypeOpen">
                        <div class="dropdown-button-container">
                            <div class="button-item">
                                <p class="button-item-text" *ngIf="edgeType==1"
                                    [innerHtml]="translate('Agito.Hilti.Profis3.CodeList.EdgeReinforcementEntity.EU.None')">
                                </p>
                                <p class="button-item-text" *ngIf="edgeType==2"
                                    [innerHtml]="translate('Agito.Hilti.Profis3.CodeList.EdgeReinforcementEntity.EU.Straight')">
                                </p>
                                <p class="button-item-text" *ngIf="edgeType==3"
                                    [innerHtml]="translate('Agito.Hilti.Profis3.CodeList.EdgeReinforcementEntity.EU.StraightCloseMesh')">
                                </p>
                            </div>
                            <div class="caret-container">
                                <div class="caret" [ngClass]="holeTypeOpen ? 'caret-up': 'caret-down'"></div>
                            </div>
                        </div>
                    </button>
                    <div class="dropdown-items" [ngClass]="{'opened': edgeTypeOpen}">
                        <button class="dropdown-item" type="button" [ngClass]="{'selected': (edgeType==1)}"
                            (click)="setEdgeType(1)">
                            <div class="item">
                                <p class="item-text"
                                    [innerHtml]="translate('Agito.Hilti.Profis3.CodeList.EdgeReinforcementEntity.EU.None')">
                                </p>
                            </div>
                        </button>
                        <button class="dropdown-item" type="button" [ngClass]="{'selected': (edgeType==2)}"
                            (click)="setEdgeType(2)">
                            <div class="item">
                                <p class="item-text"
                                    [innerHtml]="translate('Agito.Hilti.Profis3.CodeList.EdgeReinforcementEntity.EU.Straight')">
                                </p>
                            </div>
                        </button>
                        <button class="dropdown-item" type="button" [ngClass]="{'selected': (edgeType==3)}"
                            (click)="setEdgeType(3)">
                            <div class="item">
                                <p class="item-text"
                                    [innerHtml]="translate('Agito.Hilti.Profis3.CodeList.EdgeReinforcementEntity.EU.StraightCloseMesh')">
                                </p>
                            </div>
                        </button>
                    </div>
                </div>
                <div class="option-checkbox-and-text">
                    <label class="option-checkbox">
                        <input type="checkbox" [checked]="reinforcementControlCracking"
                            (change)="reinforcementControlCracking = !reinforcementControlCracking" />
                        <span class="checkmark"></span>
                    </label>
                    <p class="option-checkbox-text"
                        [innerHtml]="translate('Agito.Hilti.Profis3.Navigation.TabBaseMaterial.RegionReinforcement.ControlReinforcementToControlSplittingPresent')">
                    </p>
                </div>
            </div>
            <ng-container *ngIf="isACI">
                <div>
                    <p class="container-header"
                        [innerHtml]="translate('Agito.Hilti.Profis3.Navigation.SupplementaryReinforcement.RegionSupplementaryReinforcement')">
                    </p>
                    <p class="select-header"
                        [innerHtml]="translate('Agito.Hilti.Profis3.Navigation.TabBaseMaterial.RegionReinforcement.ControlEdgeReinforcement')">
                    </p>
                    <div [id]="edgeReinforcementOption.id" class="radio"  *ngFor="let edgeReinforcementOption of edgeReinforcementOptions">
                        <label (click)="edgeReinforcement= edgeReinforcementOption.value">
                            <div class="radio-button">
                                <input type="radio" [value]="edgeReinforcementOption.value" [(ngModel)]="edgeReinforcement">
                                <span></span>
                            </div>
                            <p [innerHtml]="edgeReinforcementOption.name">
                            </p>
                        </label>
                    </div>
                    <div class="reinforcement-condition">
                        <div class="selects-wrapper">
                            <div class="select-50">
                                <p class="select-header"
                                    [innerHtml]="translate('Agito.Hilti.Profis3.CodeList.Reinforcement.ShearCondition')">
                                </p>
                                <div class="radio">
                                    <label (click)="reinforcementShearCondition=1">
                                        <div class="radio-button">
                                            <input type="radio" [value]="1" [(ngModel)]="reinforcementShearCondition">
                                            <span></span>
                                        </div>
                                        <p
                                            [innerHtml]="translate('Agito.Hilti.Profis3.CodeList.ReinforcementCondition.A')">
                                        </p>
                                    </label>
                                </div>
                                <div class="radio">
                                    <label (click)="reinforcementShearCondition=2">
                                        <div class="radio-button">
                                            <input type="radio" [value]="2" [(ngModel)]="reinforcementShearCondition">
                                            <span></span>
                                        </div>
                                        <p
                                            [innerHtml]="translate('Agito.Hilti.Profis3.CodeList.ReinforcementCondition.B')">
                                        </p>
                                    </label>
                                </div>
                            </div>
                            <div class="select-50">
                                <p class="select-header"
                                    [innerHtml]="translate('Agito.Hilti.Profis3.CodeList.Reinforcement.TensionCondition')">
                                </p>
                                <div class="radio">
                                    <label (click)="reinforcementTensionCondition=1">
                                        <div class="radio-button">
                                            <input type="radio" [value]="1" [(ngModel)]="reinforcementTensionCondition">
                                            <span></span>
                                        </div>
                                        <p
                                            [innerHtml]="getReinforcementTensionOption(reinforcementTensionOption.Option1)">
                                        </p>
                                    </label>
                                </div>
                                <div class="radio">
                                    <label (click)="reinforcementTensionCondition=2">
                                        <div class="radio-button">
                                            <input type="radio" [value]="2" [(ngModel)]="reinforcementTensionCondition">
                                            <span></span>
                                        </div>
                                        <p
                                            [innerHtml]="getReinforcementTensionOption(reinforcementTensionOption.Option2)">
                                        </p>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <div class="installation-condition">
                <p class="container-header"
                    [innerHtml]="translate('Agito.Hilti.Profis3.Navigation.SmartAnchor.Text.InstallationCondition.Title')">
                </p>
                <div class="container-top">
                    <p class="select-header"
                        [innerHtml]="translate('Agito.Hilti.Profis3.Navigation.TabBaseMaterial.RegionInstallationConditions.ControlDrillingMethod')">
                    </p>
                    <button class="dropdown-button" type="button" (click)="drillingMethodOpen = !drillingMethodOpen;">
                        <div class="dropdown-button-container">
                            <div class="button-item">
                                <p class="button-item-text" *ngIf="drillingMethod==1"
                                    [innerHtml]="translate('Agito.Hilti.Profis3.CodeList.DrillingMethodEntity.Hammer')">
                                </p>
                                <p class="button-item-text" *ngIf="drillingMethod==2"
                                    [innerHtml]="translate('Agito.Hilti.Profis3.CodeList.DrillingMethodEntity.Diamond')">
                                </p>
                                <p class="button-item-text" *ngIf="drillingMethod==4"
                                    [innerHtml]="translate('Agito.Hilti.Profis3.CodeList.DrillingMethodEntity.DiamondRoughened')">
                                </p>
                                <p class="button-item-text" *ngIf="drillingMethod==3"
                                    [innerHtml]="translate('Agito.Hilti.Profis3.CodeList.DrillingMethodEntity.AutomaticCleaning')">
                                </p>
                            </div>
                            <div class="caret-container">
                                <div class="caret" [ngClass]="drillingMethodOpen ? 'caret-up': 'caret-down'"></div>
                            </div>
                        </div>
                    </button>
                    <div class="dropdown-items" [ngClass]="{'opened': drillingMethodOpen}">
                        <button class="dropdown-item" type="button" [ngClass]="{'selected': (drillingMethod==1)}"
                            (click)="setDrillingMethod(1)">
                            <div class="item">
                                <p class="item-text"
                                    [innerHtml]="translate('Agito.Hilti.Profis3.CodeList.DrillingMethodEntity.Hammer')">
                                </p>
                            </div>
                        </button>
                        <button class="dropdown-item" type="button" [ngClass]="{'selected': (drillingMethod==2)}"
                            (click)="setDrillingMethod(2)">
                            <div class="item">
                                <p class="item-text"
                                    [innerHtml]="translate('Agito.Hilti.Profis3.CodeList.DrillingMethodEntity.Diamond')">
                                </p>
                            </div>
                        </button>
                        <button class="dropdown-item" type="button" [ngClass]="{'selected': (drillingMethod==4)}"
                            (click)="setDrillingMethod(4)">
                            <div class="item">
                                <p class="item-text"
                                    [innerHtml]="translate('Agito.Hilti.Profis3.CodeList.DrillingMethodEntity.DiamondRoughened')">
                                </p>
                            </div>
                        </button>
                        <button class="dropdown-item" type="button" [ngClass]="{'selected': (drillingMethod==3)}"
                            (click)="setDrillingMethod(3)">
                            <div class="item">
                                <p class="item-text"
                                    [innerHtml]="translate('Agito.Hilti.Profis3.CodeList.DrillingMethodEntity.AutomaticCleaning')">
                                </p>
                            </div>
                        </button>
                    </div>
                </div>
                <div class="space">
                    <p class="select-header"
                        [innerHtml]="translate('Agito.Hilti.Profis3.Navigation.TabBaseMaterial.RegionInstallationConditions.HoleType.Tooltip.Title')">
                    </p>
                    <button class="dropdown-button" type="button" (click)="holeTypeButton()">
                        <div class="dropdown-button-container">
                            <div class="button-item">
                                <p class="button-item-text" *ngIf="holeType==1"
                                    [innerHtml]="translate('Agito.Hilti.Profis3.CodeList.HoleTypeEntity.Dry')"></p>
                                <p class="button-item-text" *ngIf="holeType==2"
                                    [innerHtml]="translate('Agito.Hilti.Profis3.CodeList.HoleTypeEntity.Wet')"></p>
                                <p class="button-item-text" *ngIf="holeType==3"
                                    [innerHtml]="translate('Agito.Hilti.Profis3.CodeList.HoleTypeEntity.WaterFilled')">
                                </p>
                                <p class="button-item-text" *ngIf="holeType==4"
                                    [innerHtml]="translate('Agito.Hilti.Profis3.CodeList.HoleTypeEntity.Submerged')">
                                </p>
                            </div>
                            <div class="caret-container">
                                <div class="caret" [ngClass]="holeTypeOpen ? 'caret-up': 'caret-down'"></div>
                            </div>
                        </div>
                    </button>
                    <div class="dropdown-items" [ngClass]="{'opened': holeTypeOpen}">
                        <button class="dropdown-item" type="button" [ngClass]="{'selected': (holeType==1)}"
                            (click)="setHoleType(1)">
                            <div class="item">
                                <p class="item-text"
                                    [innerHtml]="translate('Agito.Hilti.Profis3.CodeList.HoleTypeEntity.Dry')"></p>
                            </div>
                        </button>
                        <button class="dropdown-item" type="button" [ngClass]="{'selected': (holeType==2)}"
                            (click)="setHoleType(2)">
                            <div class="item">
                                <p class="item-text"
                                    [innerHtml]="translate('Agito.Hilti.Profis3.CodeList.HoleTypeEntity.Wet')"></p>
                            </div>
                        </button>
                        <button class="dropdown-item" type="button" [ngClass]="{'selected': (holeType==3)}"
                            (click)="setHoleType(3)">
                            <div class="item">
                                <p class="item-text"
                                    [innerHtml]="translate('Agito.Hilti.Profis3.CodeList.HoleTypeEntity.WaterFilled')">
                                </p>
                            </div>
                        </button>
                        <button class="dropdown-item" type="button" [ngClass]="{'selected': (holeType==4)}"
                            (click)="setHoleType(4)">
                            <div class="item">
                                <p class="item-text"
                                    [innerHtml]="translate('Agito.Hilti.Profis3.CodeList.HoleTypeEntity.Submerged')">
                                </p>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<pe-modal-footer>
    <pe-button class="modal-button" [look]="'Default'" (buttonClick)="close()"
        [text]="translate('Agito.Hilti.Profis3.Cancel')">
    </pe-button>

    <pe-button class="modal-button" [look]="'Cta'" (buttonClick)="save()"
        [text]="translate('Agito.Hilti.Profis3.Main.Save')">
    </pe-button>
</pe-modal-footer>
