<div class="resizer-row"
    *ngIf="dataLoaded"
    [class.loads-row]="!isAdd"
    [class.loads-row-first]="!isAdd && loadIndex == 0"
    [class.new-load]="isAdd"
    [class.dynamic]="helper.isLoadDynamic(load)"
    [class.handrail-ok]="!isAdd && helper.isHandrailOk(load)"
    [class.handrail-not-ok]="!isAdd && !helper.isHandrailOk(load)"
    [class.decisive]="!isAdd && isDecisiveLoadCombinationFn(load)">
    <!-- Active -->
    <div class="column-selected">
        <pe-radio-button
            *ngIf="!isAdd"
            class="selected-container"
            [ngbTooltip]="translate('Agito.Hilti.Profis3.Main.Loads.LoadSelect.TooltipText')"
            [disableTooltip]="!selectionDisabled"
            [items]="selectedLoadItems"
            [selectedValue]="selectedLoadId"
            (selectedValueChange)="selectLoad()"
            [disabled]="disabled || selectionDisabled || helper.isLoadCharacteristicSet(load)">
        </pe-radio-button>
    </div>

    <!-- Rank -->
    <div class="column-rank">
        <div class="name-container" [ngbTooltip]="load.Name">
            <div class="text">{{ loadIndex != null ? loadIndex + 1 : 0 }}</div>
        </div>
    </div>

    <!-- Name -->
    <div class="column-name resizer-column bordered-column" [ngStyle]="{ 'flex-basis': helper.resizerColumnWidth?.name + 'px' }">
        <div class="name-container"
            [ngbTooltip]="load.Name + (load.Description != null ? ': ' + load.Description : '')">
            <pe-text-box
                *ngIf="!load.IsWizardGenerated"
                id="{{parentId}}-{{loadIndex != null ? loadIndex : 'new-load'}}-name-input"
                class="input name-input clip"
                [borderTop]="'None'"
                [borderBottom]="'None'"
                [borderLeft]="'None'"
                [borderRight]="'None'"
                [marginBottom]="'None'"
                [display]="textBoxDisplay"
                [height]="nameInputHeight"
                [value]="load.Name"
                (inputBlur)="loadNameChanged($any($event).detail)"
                [updateOnBlur]="true"
                [disabled]="submitted || design.designType.id == helper.designTypeEnum.Handrail || helper.isLoadCharacteristicSet(load)">
            </pe-text-box>

            <div class="text clip" *ngIf="load.IsWizardGenerated">{{load.Name}}</div>
        </div>

        <div class="name-container bottom"
            *ngIf="load.Description != null && helper.showDynamic"
            [ngbTooltip]="load.Name + (load.Description != null ? ': ' + load.Description : '')">
            <div class="text clip disabled-text">{{load.Description}}</div>
        </div>

        <div class="resizer-right" (mousedown)="beginColumnResizing($event)"></div>
    </div>

    <!-- Load type -->
    <!-- Size of this column cannot be edited by the user, so the element after it has to include the left-resizer. -->
    <div class="column-type" *ngIf="helper.areLoadTypesAvailableForLoadCombinations"
        [ngStyle]="{ 'flex-basis': helper.columnTypeWidth + 'px' }">
        <pe-toggle-button-group
            class="type-buttons"
            [model]="load.ActiveLoadType"
            (modelChange)="loadActiveLoadTypeChange($any($event).detail)"
            [items]="loadTypeToggleItems"
            [disabled]="!helper.isSmartAnchorToggleON && (submitted || load.IsWizardGenerated)">
        </pe-toggle-button-group>
    </div>

    <!-- Loads legend -->
    <div class="column-loads" *ngIf="helper.showDynamic">
        <div class="name-container" [ngbTooltip]="helper.firstRowLoadLegendTooltip">
            <div class="text clip bordered-text">{{helper.firstRowLoadLegendTranslation}}</div>
        </div>

        <div class="dynamic-container"
            *ngIf="helper.isLoadNormalOrUnfactored(load)"
            [ngbTooltip]="helper.secondRowLoadLegendTooltip">
            <div class="text clip bordered-text">{{helper.secondRowLoadLegendTranslation}}</div>
        </div>
    </div>

    <!-- Forces -->
    <div class="column-force column-vx resizer-column" [ngStyle]="{ 'flex-basis': helper.resizerColumnWidth?.forceVx + 'px' }">
        <div class="general-number">
            <div class="number-container">
                <pe-numeric-text-box
                    id="{{parentId}}-{{loadIndex != null ? loadIndex : 'new-load'}}-vx-button"
                    class="input vx-input number-input clip"
                    [borderTop]="'None'"
                    [borderBottom]="'None'"
                    [borderLeft]="'None'"
                    [borderRight]="'None'"
                    [marginBottom]="'None'"
                    [display]="textBoxDisplay"
                    [value]="load.ForceX"
                    (valueChange)="loadValueChange('ForceX', $any($event).detail)"
                    [showStepper]="false"
                    [unit]="helper.forceUnit"
                    [appendUnit]="false"
                    [updateOnBlur]="true"
                    [required]="true"
                    [minValue]="helper.forceXRange?.min"
                    [maxValue]="helper.forceXRange?.max"
                    [disabled]="submitted || design.designType.id == helper.designTypeEnum.Handrail || load.IsWizardGenerated">
                </pe-numeric-text-box>
            </div>
        </div>

        <div class="dynamic-number">
            <div class="number-container">
                <pe-numeric-text-box
                    *ngIf="(!helper.isSustained) && helper.isLoadNormalOrUnfactored(load)"
                    id="{{parentId}}-{{loadIndex != null ? loadIndex : 'new-load'}}-dvx-button"
                    class="input vx-input number-input clip"
                    [borderTop]="'None'"
                    [borderBottom]="'None'"
                    [borderLeft]="'None'"
                    [borderRight]="'None'"
                    [marginBottom]="'None'"
                    [display]="textBoxDisplay"
                    [value]="load.DynamicForceX"
                    (valueChange)="loadValueChange('DynamicForceX', $any($event).detail)"
                    [showStepper]="false"
                    [unit]="helper.forceUnit"
                    [appendUnit]="false"
                    [updateOnBlur]="true"
                    [required]="true"
                    [minValue]="helper.dynamicForceXRange?.min"
                    [maxValue]="helper.dynamicForceXRange?.max"
                    [disabled]="submitted || design.designType.id == helper.designTypeEnum.Handrail || load.HasSustainedLoads || load.IsWizardGenerated || helper.isFactoredSustainedLoadsActive">
                </pe-numeric-text-box>
            </div>
        </div>

        <div class="resizer-left" (mousedown)="beginColumnResizing($event)"></div>
        <div class="resizer-right" (mousedown)="beginColumnResizing($event)"></div>
    </div>

    <div class="column-force column-vy resizer-column" [ngStyle]="{ 'flex-basis': helper.resizerColumnWidth?.forceVy + 'px' }">
        <div class="general-number">
            <div class="number-container">
                <pe-numeric-text-box
                    id="{{parentId}}-{{loadIndex != null ? loadIndex : 'new-load'}}-vy-button"
                    class="input vy-input number-input clip"
                    [borderTop]="'None'"
                    [borderBottom]="'None'"
                    [borderLeft]="'None'"
                    [borderRight]="'None'"
                    [marginBottom]="'None'"
                    [display]="textBoxDisplay"
                    [value]="load.ForceY"
                    (valueChange)="loadValueChange('ForceY', $any($event).detail)"
                    [showStepper]="false"
                    [unit]="helper.forceUnit"
                    [appendUnit]="false"
                    [updateOnBlur]="true"
                    [required]="true"
                    [minValue]="helper.forceYRange?.min"
                    [maxValue]="helper.forceYRange?.max"
                    [disabled]="submitted || design.designType.id == helper.designTypeEnum.Handrail || load.IsWizardGenerated">
                </pe-numeric-text-box>
            </div>
        </div>

        <div class="dynamic-number">
            <div class="number-container">
                <pe-numeric-text-box
                    *ngIf="(!helper.isSustained) && helper.isLoadNormalOrUnfactored(load)"
                    id="{{parentId}}-{{loadIndex != null ? loadIndex : 'new-load'}}-vy-button"
                    class="input vy-input number-input clip"
                    [borderTop]="'None'"
                    [borderBottom]="'None'"
                    [borderLeft]="'None'"
                    [borderRight]="'None'"
                    [marginBottom]="'None'"
                    [display]="textBoxDisplay"
                    [value]="load.DynamicForceY"
                    (valueChange)="loadValueChange('DynamicForceY', $any($event).detail)"
                    [showStepper]="false"
                    [unit]="helper.forceUnit"
                    [appendUnit]="false"
                    [updateOnBlur]="true"
                    [required]="true"
                    [minValue]="helper.dynamicForceYRange?.min"
                    [maxValue]="helper.dynamicForceYRange?.max"
                    [disabled]="submitted || design.designType.id == helper.designTypeEnum.Handrail || load.HasSustainedLoads || load.IsWizardGenerated || helper.isFactoredSustainedLoadsActive">
                </pe-numeric-text-box>
            </div>
        </div>

        <div class="resizer-right" (mousedown)="beginColumnResizing($event)"></div>
    </div>

    <div class="column-force column-n resizer-column" [ngStyle]="{ 'flex-basis': helper.resizerColumnWidth?.forceN + 'px' }">
        <div class="general-number">
            <div class="number-container">
                <pe-numeric-text-box
                    id="{{parentId}}-{{loadIndex != null ? loadIndex : 'new-load'}}-n-button"
                    class="input n-input number-input clip"
                    [borderTop]="'None'"
                    [borderBottom]="'None'"
                    [borderLeft]="'None'"
                    [borderRight]="'None'"
                    [marginBottom]="'None'"
                    [display]="textBoxDisplay"
                    [value]="load.ForceZ"
                    (valueChange)="loadValueChange('ForceZ',$any($event).detail)"
                    [showStepper]="false"
                    [unit]="helper.forceUnit"
                    [appendUnit]="false"
                    [updateOnBlur]="true"
                    [required]="true"
                    [minValue]="helper.forceZRange?.min"
                    [maxValue]="helper.forceZRange?.max"
                    [disabled]="submitted || design.designType.id == helper.designTypeEnum.Handrail || !helper.areAllLoadsForLedgerAngleAvailable || load.IsWizardGenerated">
                </pe-numeric-text-box>
            </div>
        </div>

        <div class="dynamic-number">
            <div class="number-container">
                <pe-numeric-text-box
                    *ngIf="(!design.isFatigueExpertMode) && helper.isLoadNormalOrUnfactored(load)"
                    id="{{parentId}}-{{loadIndex != null ? loadIndex : 'new-load'}}-dn-button"
                    class="input n-input number-input clip"
                    [borderTop]="'None'"
                    [borderBottom]="'None'"
                    [borderLeft]="'None'"
                    [borderRight]="'None'"
                    [marginBottom]="'None'"
                    [display]="textBoxDisplay"
                    [value]="load.DynamicForceZ"
                    (valueChange)="loadValueChange('DynamicForceZ', $any($event).detail)"
                    [showStepper]="false"
                    [unit]="helper.forceUnit"
                    [appendUnit]="false"
                    [updateOnBlur]="true"
                    [required]="true"
                    [minValue]="helper.dynamicForceZRange?.min"
                    [maxValue]="helper.dynamicForceZRange?.max"
                    [disabled]="submitted || design.designType.id == helper.designTypeEnum.Handrail || !helper.areAllLoadsForLedgerAngleAvailable || load.IsWizardGenerated || helper.isFactoredSustainedLoadsActive">
                </pe-numeric-text-box>
            </div>
        </div>

        <div class="resizer-right" (mousedown)="beginColumnResizing($event)"></div>
    </div>

    <!-- Moments -->
    <div class="column-moment column-mx resizer-column" [ngStyle]="{ 'flex-basis': helper.resizerColumnWidth?.momentMx + 'px' }">
        <div class="general-number">
            <div class="number-container">
                <pe-numeric-text-box
                    id="{{parentId}}-{{loadIndex != null ? loadIndex : 'new-load'}}-mx-button"
                    class="input mx-input number-input clip"
                    [borderTop]="'None'"
                    [borderBottom]="'None'"
                    [borderLeft]="'None'"
                    [borderRight]="'None'"
                    [marginBottom]="'None'"
                    [display]="textBoxDisplay"
                    [value]="load.MomentX"
                    (valueChange)="loadValueChange('MomentX', $any($event).detail)"
                    [showStepper]="false"
                    [unit]="helper.momentUnit"
                    [appendUnit]="false"
                    [updateOnBlur]="true"
                    [required]="true"
                    [minValue]="helper.momentXRange?.min"
                    [maxValue]="helper.momentXRange?.max"
                    [disabled]="submitted || design.designType.id == helper.designTypeEnum.Handrail || !helper.areMomentsForMetalDeckAvailable || !helper.areAllLoadsForLedgerAngleAvailable || load.IsWizardGenerated">
                </pe-numeric-text-box>
            </div>
        </div>

        <div class="dynamic-number">
            <div class="number-container">
                <pe-numeric-text-box
                    *ngIf="(!design.isFatigueExpertMode) && helper.isLoadNormalOrUnfactored(load)"
                    id="{{parentId}}-{{loadIndex != null ? loadIndex : 'new-load'}}-dmx-button"
                    class="input mx-input number-input clip"
                    [borderTop]="'None'"
                    [borderBottom]="'None'"
                    [borderLeft]="'None'"
                    [borderRight]="'None'"
                    [marginBottom]="'None'"
                    [display]="textBoxDisplay"
                    [value]="load.DynamicMomentX"
                    (valueChange)="loadValueChange('DynamicMomentX', $any($event).detail)"
                    [showStepper]="false"
                    [unit]="helper.momentUnit"
                    [appendUnit]="false"
                    [updateOnBlur]="true"
                    [required]="true"
                    [minValue]="helper.dynamicMomentXRange?.min"
                    [maxValue]="helper.dynamicMomentXRange?.max"
                    [disabled]="submitted || design.designType.id == helper.designTypeEnum.Handrail || !helper.areMomentsForMetalDeckAvailable || !helper.areAllLoadsForLedgerAngleAvailable || load.IsWizardGenerated || helper.isFactoredSustainedLoadsActive">
                </pe-numeric-text-box>
            </div>
        </div>

        <div class="resizer-right" (mousedown)="beginColumnResizing($event)"></div>
    </div>

    <div class="column-moment column-my resizer-column" [ngStyle]="{ 'flex-basis': helper.resizerColumnWidth?.momentMy + 'px' }">
        <div class="general-number">
            <div class="number-container">
                <pe-numeric-text-box
                    id="{{parentId}}-{{loadIndex != null ? loadIndex : 'new-load'}}-my-button"
                    class="input my-input number-input clip"
                    [borderTop]="'None'"
                    [borderBottom]="'None'"
                    [borderLeft]="'None'"
                    [borderRight]="'None'"
                    [marginBottom]="'None'"
                    [display]="textBoxDisplay"
                    [value]="load.MomentY"
                    (valueChange)="loadValueChange('MomentY', $any($event).detail)"
                    [showStepper]="false"
                    [unit]="helper.momentUnit"
                    [appendUnit]="false"
                    [updateOnBlur]="true"
                    [required]="true"
                    [minValue]="helper.momentYRange?.min"
                    [maxValue]="helper.momentYRange?.max"
                    [disabled]="submitted || design.designType.id == helper.designTypeEnum.Handrail || !helper.areMomentsForMetalDeckAvailable || !helper.areAllLoadsForLedgerAngleAvailable || load.IsWizardGenerated">
                </pe-numeric-text-box>
            </div>
        </div>

        <div class="dynamic-number">
            <div class="number-container">
                <pe-numeric-text-box
                    *ngIf="(!design.isFatigueExpertMode) && helper.isLoadNormalOrUnfactored(load)"
                    id="{{parentId}}-{{loadIndex != null ? loadIndex : 'new-load'}}-dmy-button"
                    class="input my-input number-input clip"
                    [borderTop]="'None'"
                    [borderBottom]="'None'"
                    [borderLeft]="'None'"
                    [borderRight]="'None'"
                    [marginBottom]="'None'"
                    [display]="textBoxDisplay"
                    [value]="load.DynamicMomentY"
                    (valueChange)="loadValueChange('DynamicMomentY', $any($event).detail)"
                    [showStepper]="false"
                    [unit]="helper.momentUnit"
                    [appendUnit]="false"
                    [updateOnBlur]="true"
                    [required]="true"
                    [minValue]="helper.dynamicMomentYRange?.min"
                    [maxValue]="helper.dynamicMomentYRange?.max"
                    [disabled]="submitted || design.designType.id == helper.designTypeEnum.Handrail || !helper.areMomentsForMetalDeckAvailable || !helper.areAllLoadsForLedgerAngleAvailable || load.IsWizardGenerated || helper.isFactoredSustainedLoadsActive">
                </pe-numeric-text-box>
            </div>
        </div>

        <div class="resizer-right" (mousedown)="beginColumnResizing($event)"></div>
    </div>

    <div class="column-moment column-mz resizer-column" [ngStyle]="{ 'flex-basis': helper.resizerColumnWidth?.momentMz + 'px' }">
        <div class="general-number">
            <div class="number-container">
                <pe-numeric-text-box
                    id="{{parentId}}-{{loadIndex != null ? loadIndex : 'new-load'}}-mz-button"
                    class="input mz-input number-input clip"
                    [borderTop]="'None'"
                    [borderBottom]="'None'"
                    [borderLeft]="'None'"
                    [borderRight]="'None'"
                    [marginBottom]="'None'"
                    [display]="textBoxDisplay"
                    [value]="load.MomentZ"
                    (valueChange)="loadValueChange('MomentZ', $any($event).detail)"
                    [showStepper]="false"
                    [unit]="helper.momentUnit"
                    [appendUnit]="false"
                    [updateOnBlur]="true"
                    [required]="true"
                    [minValue]="helper.momentZRange?.min"
                    [maxValue]="helper.momentZRange?.max"
                    [disabled]="submitted || design.designType.id == helper.designTypeEnum.Handrail || !helper.areMomentsForMetalDeckAvailable || !helper.areAllLoadsForLedgerAngleAvailable || load.IsWizardGenerated">
                </pe-numeric-text-box>
            </div>
        </div>

        <div class="dynamic-number">
            <div class="number-container">
                <pe-numeric-text-box
                    *ngIf="(!helper.isSustained) && helper.isLoadNormalOrUnfactored(load)"
                    id="{{parentId}}-{{loadIndex != null ? loadIndex : 'new-load'}}-dmz-button"
                    class="input mz-input number-input clip"
                    [borderTop]="'None'"
                    [borderBottom]="'None'"
                    [borderLeft]="'None'"
                    [borderRight]="'None'"
                    [marginBottom]="'None'"
                    [display]="textBoxDisplay"
                    [value]="load.DynamicMomentZ"
                    (valueChange)="loadValueChange('DynamicMomentZ', $any($event).detail)"
                    [showStepper]="false"
                    [unit]="helper.momentUnit"
                    [appendUnit]="false"
                    [updateOnBlur]="true"
                    [required]="true"
                    [minValue]="helper.dynamicMomentZRange?.min"
                    [maxValue]="helper.dynamicMomentZRange?.max"
                    [disabled]="submitted || design.designType.id == helper.designTypeEnum.Handrail || !helper.areMomentsForMetalDeckAvailable || !helper.areAllLoadsForLedgerAngleAvailable || load.HasSustainedLoads || load.IsWizardGenerated || helper.isFactoredSustainedLoadsActive">
                </pe-numeric-text-box>
            </div>
        </div>

        <div class="resizer-right" (mousedown)="beginColumnResizing($event)"></div>
    </div>

    <!-- Results -->
    <!-- isAdd == true -->
    <div *ngIf="isAdd" class="column-add resizer-column" [ngStyle]="{ 'flex-basis': helper.resizerColumnWidth?.utilization + 'px' }">
        <div class="add-buttons">
            <pe-button
                id="{{parentId}}-confirm-add-button"
                class="confirm-add-button button button-default"
                [look]="'DefaultIcon'"
                (click)="addNewLoad()"
                [disabled]="submitted"
                [ngbTooltip]="translate('Agito.Hilti.Profis3.Main.Loads.ConfirmAdd')">
                <span class="pe-ui-pe-sprite pe-ui-pe-sprite-ok"></span>
            </pe-button>

            <pe-button
                id="{{parentId}}-cancel-add-button"
                class="cancel-add-button button button-default"
                [look]="'DefaultIcon'"
                [disabled]="submitted"
                (click)="helper.addingNewLoad = false"
                [ngbTooltip]="translate('Agito.Hilti.Profis3.Main.Loads.CancelAdd')">
                <span class="pe-ui-pe-sprite pe-ui-pe-sprite-x"></span>
            </pe-button>
        </div>
    </div>

    <div class="column-messages" *ngIf="isAdd && design.designType.id != helper.designTypeEnum.Handrail"></div>

    <div class="column-delete" *ngIf="isAdd && design.designType.id != helper.designTypeEnum.Handrail"></div>

    <!-- isAdd == false -->
    <div *ngIf="isAdd == false" class="column-utilization resizer-column right-side-border"
        [ngStyle]="{ 'flex-basis': helper.resizerColumnWidth?.utilization + 'px' }">
        <ng-template #utilizationTooltip>
            <div class="utilization-container" style="text-align: left;">
                <div class="utilization-container-item" style="display: flex" *ngIf="load.Tension?.Value != null">
                    <span>{{translate('Agito.Hilti.Profis3.Loads.Utilization.Tooltip.Tension').replace('{tension}', helper.formatPercentage(load.Tension?.Value))}}</span>
                    <span style="margin-left: 8px;" class="image sprite sprite-warning-gray" *ngIf="load.Tension?.ValueError"></span>
                </div>

                <div class="utilization-container-item" style="display: flex" *ngIf="load.Shear?.Value != null">
                    <span>{{translate('Agito.Hilti.Profis3.Loads.Utilization.Tooltip.Shear').replace('{shear}', helper.formatPercentage(load.Shear?.Value))}}</span>
                    <span style="margin-left: 8px;" class="image sprite sprite-warning-gray" *ngIf="load.Shear?.ValueError"></span>
                </div>

                <div class="utilization-container-item" style="display: flex" *ngIf="load.Combination?.Value != null">
                    <span>{{translate('Agito.Hilti.Profis3.Loads.Utilization.Tooltip.Combination').replace('{combination}', helper.formatPercentage(load.Combination?.Value))}}</span>
                    <span style="margin-left: 8px;" class="image sprite sprite-warning-gray" *ngIf="load.Combination?.ValueError"></span>
                </div>
            </div>
        </ng-template>

        <div *ngIf="isUtilizationVisible" class="general-utilization notranslate">
            <div class="utilization-number clip" [class.invalid-value]="helper.isTensionInvalid(load)"
                *ngIf="!isUtilizationCompact">{{helper.formatPercentage(load.Tension?.Value)}}</div>
            <div class="utilization-number clip" [class.invalid-value]="helper.isShearInvalid(load)"
                *ngIf="!isUtilizationCompact">{{helper.formatPercentage(load.Shear?.Value)}}</div>
            <div class="utilization-number clip" [class.invalid-value]="helper.isCombinationInvalid(load)"
                *ngIf="!isUtilizationCompact">{{helper.formatPercentage(load.Combination?.Value)}}</div>
            <div class="utilization-number clip" [class.invalid-value]="helper.isTotalInvalid(load)"
                [ngbTooltip]="utilizationTooltip"
                [openDelay]="0"
                tooltipClass="loads-utilization-tooltip"
                [disableTooltip]="!(load.Tension?.Value != null || load.Shear?.Value != null || load.Combination?.Value != null || load.StructuralSafetyConnectionPlates?.Value != null || load.StructuralSafetyConnectionWelds?.Value != null)">
                {{helper.utilizationValue(
                    load.Combination?.Value,
                    load.Tension?.Value,
                    load.Shear?.Value,
                    load.StructuralSafetyConnectionPlates ? load.StructuralSafetyConnectionPlates.Value : null,
                    load.StructuralSafetyConnectionWelds ? load.StructuralSafetyConnectionWelds.Value : null)
                }}
            </div>
        </div>
        <div class="resizer-right" (mousedown)="beginColumnResizing($event)" *ngIf="design.designType.id == helper.designTypeEnum.Handrail && showPostAndRailDeflection"></div>
    </div>

    <div class="column-handrail resizer-column"
        *ngIf="isAdd == false && design.designType.id == helper.designTypeEnum.Handrail && showPostAndRailDeflection"
        [ngStyle]="{ 'flex-basis': helper.resizerColumnWidth?.handrail + 'px' }">
        <div class="general-utilization">
            <ng-template #utilizationHandrailTooltip>
                <div class="utilization-container" style="text-align: left;">
                    <div class="utilization-container-item" style="display: flex" *ngIf="load.StructuralSafetyRail != null">
                        <span>{{translate('Agito.Hilti.Profis3.Loads.Utilization.Handrail.Tooltip.StructuralSafetyRail').replace('{rail}', helper.formatPercentage(load.StructuralSafetyRail.Value))}}</span>
                        <span style="margin-left: 8px;" class="image sprite sprite-warning-gray"
                            *ngIf="(load.StructuralSafetyRail.Value ?? 0) > 100 || (load.StructuralSafetyRail.Value ?? 0) < 0"></span>
                    </div>

                    <div class="utilization-container-item" style="display: flex" *ngIf="load.StructuralSafetyPost != null">
                        <span>{{translate('Agito.Hilti.Profis3.Loads.Utilization.Handrail.Tooltip.StructuralSafetyPost').replace('{post}', helper.formatPercentage(load.StructuralSafetyPost.Value))}}</span>
                        <span style="margin-left: 8px;" class="image sprite sprite-warning-gray"
                            *ngIf="(load.StructuralSafetyPost.Value ?? 0) > 100 || (load.StructuralSafetyPost.Value ?? 0) < 0"></span>
                        </div>

                    <div class="utilization-container-item" style="display: flex" *ngIf="load.StructuralSafetyConnectionPlates != null && load.StructuralSafetyConnectionPlates.Value != null">
                        <span>{{translate('Agito.Hilti.Profis3.Loads.Utilization.Tooltip.Plates').replace('{plates}', helper.formatPercentage(load.StructuralSafetyConnectionPlates.Value))}}</span>
                        <span style="margin-left: 8px;" class="image sprite sprite-warning-gray" *ngIf="load.StructuralSafetyConnectionPlates.ValueError"></span>
                    </div>

                    <div class="utilization-container-item" style="display: flex" *ngIf="load.StructuralSafetyConnectionWelds != null && load.StructuralSafetyConnectionWelds.Value != null">
                        <span>{{translate('Agito.Hilti.Profis3.Loads.Utilization.Tooltip.Welds').replace('{welds}', helper.formatPercentage(load.StructuralSafetyConnectionWelds.Value))}}</span>
                        <span style="margin-left: 8px;" class="image sprite sprite-warning-gray" *ngIf="load.StructuralSafetyConnectionWelds.ValueError"></span>
                    </div>

                    <div class="utilization-container-item" style="display: flex" *ngIf="load.TotalDeflection != null">
                        <span>{{translate('Agito.Hilti.Profis3.Loads.Utilization.Handrail.Tooltip.TotalDeflection').replace('{deflection}', helper.formatLength(load.TotalDeflection.Value))}}</span>
                    </div>
                </div>
            </ng-template>

            <div *ngIf="isHandrailUtilizationCompact"
                class="utilization-number utilization-number-handrail clip notranslate"
                [class.invalid-value]="load.StructuralSafetyRail && load.StructuralSafetyRail.ValueError || load.StructuralSafetyPost && load.StructuralSafetyPost.ValueError || load.TotalDeflection && load.TotalDeflection.ValueError"
                [ngbTooltip]="utilizationHandrailTooltip"
                [openDelay]="0"
                tooltipClass="loads-utilization-tooltip"
                [disableTooltip]="!(load.StructuralSafetyPost != null || load.StructuralSafetyRail != null || load.TotalDeflection != null)">
                {{helper.utilizationValue(load.StructuralSafetyPost?.Value, load.StructuralSafetyRail?.Value, null, null, null)}}
            </div>

            <div *ngIf="!isHandrailUtilizationCompact"
                class="utilization-number utilization-number-handrail clip notranslate"
                [class.invalid-value]="load.StructuralSafetyRail && load.StructuralSafetyRail.ValueError">
                {{helper.formatPercentage(load.StructuralSafetyRail?.Value)}}
            </div>

            <div *ngIf="!isHandrailUtilizationCompact"
                class="utilization-number utilization-number-handrail clip notranslate"
                [class.invalid-value]="load.StructuralSafetyPost && load.StructuralSafetyPost.ValueError">
                {{helper.formatPercentage(load.StructuralSafetyPost?.Value)}}
            </div>

            <ng-template #utilizationHandrailConnectionTooltip>
                <div class="utilization-container" style="text-align: left;">
                    <div class="utilization-container-item" *ngIf="load.StructuralSafetyConnectionPlates?.Value != null">
                        <span>{{translate('Agito.Hilti.Profis3.Loads.Utilization.Tooltip.Plates').replace('{plates}', helper.formatPercentage(load.StructuralSafetyConnectionPlates?.Value))}}</span>
                        <span style="margin-left: 8px;" class="image sprite sprite-warning-gray" *ngIf="load.StructuralSafetyConnectionPlates?.ValueError"></span>
                    </div>

                    <div class="utilization-container-item" *ngIf="load.StructuralSafetyConnectionWelds?.Value != null">
                        <span>{{translate('Agito.Hilti.Profis3.Loads.Utilization.Tooltip.Welds').replace('{welds}', helper.formatPercentage(load.StructuralSafetyConnectionWelds?.Value))}}</span>
                        <span style="margin-left: 8px;" class="image sprite sprite-warning-gray" *ngIf="load.StructuralSafetyConnectionWelds?.ValueError"></span>
                    </div>
                </div>
            </ng-template>

            <div *ngIf="!isHandrailUtilizationCompact && design.isHandrailCBFEMCalculation"
                class="utilization-number utilization-number-handrail utilization-number-wide clip notranslate"
                [class.invalid-value]="isStructuralSafetyConnectionPlatesInvalid() || isStructuralSafetyConnectionWeldsInvalid()"
                [ngbTooltip]="utilizationHandrailConnectionTooltip"
                [openDelay]="0"
                tooltipClass="loads-utilization-tooltip"
                [disableTooltip]="!(load.StructuralSafetyConnectionPlates?.Value != null || load.StructuralSafetyConnectionWelds?.Value != null)">
                {{helper.utilizationValue(
                    load.StructuralSafetyConnectionPlates ? load.StructuralSafetyConnectionPlates.Value : null,
                    load.StructuralSafetyConnectionWelds ? load.StructuralSafetyConnectionWelds.Value : null,
                    null,
                    null,
                    null)
                }}
            </div>

            <div *ngIf="!isHandrailUtilizationCompact"
                class="utilization-number utilization-number-handrail utilization-number-wide clip notranslate"
                [class.invalid-value]="load.TotalDeflection && load.TotalDeflection.ValueError">
                {{helper.formatLength(load.TotalDeflection?.Value)}}
            </div>
        </div>

        <div class="resizer-left" (mousedown)="beginColumnResizing($event)"></div>
    </div>

    <div class="column-messages" *ngIf="isAdd == false && design.designType.id != helper.designTypeEnum.Handrail">
        <button
            id="{{parentId}}-{{loadIndex != null ? loadIndex : 'new-load'}}-messages-button"
            type="button"
            class="messages-button"
            *ngIf="helper.hasMessages(load)"
            (click)="helper.showMessages(load)"
            [disabled]="submitted"
            [ngbTooltip]="translate('Agito.Hilti.Profis3.Main.Loads.Messages')">
            <span class="pe-ui-pe-sprite pe-ui-pe-sprite-warning"></span>
        </button>
    </div>

    <div class="column-delete" *ngIf="isAdd == false && design.designType.id != helper.designTypeEnum.Handrail">
        <div class="tooltip-wrap"
            [disableTooltip]="submitted"
            [ngbTooltip]="translate('Agito.Hilti.Profis3.Main.Loads.Delete')">
            <button
                id="{{parentId}}-{{loadIndex != null ? loadIndex : 'new-load'}}-delete-button"
                type="button"
                class="delete-button"
                (click)="deleteLoad(load)"
                [disabled]="load.Id == selectedLoadId || load.IsWizardGenerated || helper.haveSingleLoad || submitted || helper.deleteLoadDisabled">
                <span class="pe-ui-pe-sprite pe-ui-pe-sprite-trash"></span>
            </button>
        </div>
    </div>
</div>
