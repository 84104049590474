export type MainMenuCommandPE =
    'OpenImportLoadsPopup' |
    'OpenCustomizeDetailsPopup' |
    'OpenInputHandrailLoadsPopup' |
    'OpenWindloadsPopup' |
    'Open2dEditor' |
    'OpenApproval' |
    'OpenDlubalImportPopup' |
    'OpenDlubalExportPopup' |
    'OpenDefineAnchorsPopup' |
    'OpenFullscreenLoader' |
    'OpenOnSiteTestsValuesPopup' |
    'OpenAdvancedCalculation' |
    'OpenAdvancedSettings' |
    'OpenNotOwnedLearnMore' |
    'OpenExportRisa' |
    'OpenSAP2000ImportPopup' |
    'OpenHorizontalPostProfileConnectionPopup' |
    'OpenAdvancedCalculationTypeInfoPopup' |
    'OpenRobotImportPopup' |
    'OpenETABSImportPopup' |
    'OpenStaadProImportPopup' |
    'OpenSupplementaryReinforcementInfoPopup' |
    'OpenSeismicFillingSetPopup' |
    'OpenAnchorDetailedFilterPopup' |
    'OpenAnchorNeedSolutionPopup' |
    'OpenAutomaticTorquePopUp' |
    'OpenAdvancedInputs' |
    'OpenWHGInfoPopup' |
    'OpenFillHolesPopup' |
    'OpenMoreInfoOnSOFAPopup' |
    'OpenMoreInfoOnFatiguePopup' |
    'OpenMoreInfoOnFireRedirect';

export enum MainMenuModal {
    brickSize = 0,
    baseplateSize = 1,
    selectMaterial = 3,
    profileSize = 4,
    onSiteTests = 5,
    selectProfile = 6
}