export enum DesignCodeList {
    AnchorFilter,
    AnchorFilterGroup,
    AnchorEmbedmentType,
    AnchorFamily,
    AnchorSize,
    AnchorType,
    AnchorEmbedmentDepth,
    ConcreteCharacteristic,
    ConcreteGrade,
    ConcreteReinforcement,
    EdgeReinforcement,
    DrillingMethod,
    HoleCondition,
    HoleType,
    PlateShape,
    ReinforcementShearCondition,
    ReinforcementTensionCondition,
    GroutType,
    LoadType,
    StaticLoadType,
    SeismicCategory,
    SeismicDisplacementsType,
    FatigueLoadType,
    FireExposure,
    FireDuration,
    InstallationType,
    EmbedmentOptimizationType,
    DesignMethodHNA,
    StandOff,
    Grout,
    AnchorLayout,
    AnchorTorqueType,
    ProfileFamily,
    ProfileSize,
    SeismicDesignType,
    SeismicShearType,
    SeismicTensionType,
    ConcreteEdge,
    BrickLayout,
    BrickGroup,
    BrickStrength,
    JointMaterial,
    MasonryUseCategory,
    MasonryFastenerConfiguration,
    CleaningMethod,
    FasteningOption,
    EnvironmentType,
    BeamType,
    HorizontalPostProfileConnectionType,
    CustomLoadCategory,
    HandrailApplication,
    BuildingCategory,
    SteelType,
    WindZone,
    BuildingArea,
    TerrainCategory,
    WindLoadState,
    WindLoadCity,
    TerrainRoughness,
    DistanceUpwindToShoreline,
    DistanceInsideTownTerrain,
    WindVelocity,
    TerrainType,
    AnchorLayoutVisible,
    AnchorLayoutSymmetry,
    CoordinateSystemCenter,
    MetalDeck,
    MetalDeckAnchorPosition,
    MasonryAnchorPosition,
    LoadCombinationHNAEquation,
    LoadCombinationHNAFactors,
    TorquingType,
    WeldLocations,
    StiffenerShape,
    StiffenerLayout,
    StiffenerLayoutPosition,
    ProfileStandard,
    InstallationDirection,
    WeldingType,
    CladdingType,
    CladdingProfile,
    DeckThickness,
    StiffenerCustomProfileSides,
    SupplementaryReinforcementDirectionOfCasting,
    SupplementaryReinforcementType,
    SupplementaryReinforcementDiameter,
    SupplementaryReinforcementCategory,
    LoadTypeC2C,
    FireDurationC2C,
    SmartAnchorCategory,
    SmartAnchorApplication,
    SmartAnchorNeedsSolutionTextsAnchorGroup,
    HandrailConnectionType,
    HandrailSafetyClass,
    AnchorPositionsAsad,
    ProfilePositionsAsad,
    SeismicIntensityFactor,
    SafetyLevel,
    WHGType,
    BrickSize,
    StandOffDesignMethod,
    BrickStrengthAC58,
    AnchorTypeAC58,
    AnchorSizeAC58,
    AnchorFamilyAC58,
    HeadJointSelection,
    ConcreteType,
    ShearLoadDistributionType
}
