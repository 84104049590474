import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DateWithTimezone, getJSONDateWithTimezoneOffset } from '@profis-engineering/pe-ui-common/helpers/date-time-helper';
import { environment } from '../../environments/environmentPe';
import { DesignType as DesignTypeEnum } from '../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import { TrackOnProjectTemplateOpenRequest, UserDetails } from '../../shared/generated-modules/Hilti.PE.Core.Tracking.Entities';
import { ApiService } from './api.service';
import { CommonTrackingService } from './common-tracking.service';
import { SharedEnvironmentService } from './shared-environment.service';

@Injectable({
    providedIn: 'root'
})
export class TrackingService {
    constructor(
        private apiService: ApiService,
        private commonTrackingService: CommonTrackingService,
        private sharedEnvironmentService: SharedEnvironmentService
    ) { }

    public async trackOnTemplateOpen(templateId: string, designType: DesignTypeEnum): Promise<void> {
        const date = getJSONDateWithTimezoneOffset();
        const url = `${environment.trackingWebServiceUrl}TrackOnProjectTemplateOpen`;
        const data = this.getTrackOnProjectTemplateOpenRequest(templateId, date, designType);

        const request = new HttpRequest('POST', url, data, {
            responseType: 'json'
        });

        await this.apiService.request(request, { supressErrorMessage: true });
    }

    private getTrackOnProjectTemplateOpenRequest(templateId: string, date: DateWithTimezone, designType: DesignTypeEnum): TrackOnProjectTemplateOpenRequest {
        return {
            DesignType: designType,
            TemplateId: templateId,
            Timestamp: date.timestamp as any,
            UIVersion: this.sharedEnvironmentService.data?.applicationVersion as string,
            UserDetails: this.getUserDetails(date.timezoneOffset),
        };
    }

    private getUserDetails(timezoneOffset: number): UserDetails {
        const srcData = this.commonTrackingService.getTrackingUserData(timezoneOffset);

        return {
            BrowserType: srcData.BrowserType,
            OperatingSystem: srcData.OperatingSystem,
            TimezoneOffset: srcData.TimezoneOffset ?? 0,
            UserIP: srcData.UserIpAddress,
            Username: srcData.UserName,
            UUID: srcData.UserId,
            DiagnosticsAgreement: srcData.DiagnosticsAgreement,
            CustomerId: srcData.CustomerId,
            CustomerOriginId: srcData.CustomerOriginId,
            SalesOrg: srcData.SalesOrg,
            CustomerType: srcData.CustomerType,
            License: srcData.License,
            IsTrial: srcData.IsTrial,
            CountryOfResidence: srcData.CountryOfResidence,
            CustomerCountry: srcData.CustomerCountry,
            BuyingCustomerOriginId: srcData.BuyingCustomerOriginId,
            BuyingSalesOrg: srcData.BuyingSalesOrg,
            BuyingCustomerType: srcData.BuyingCustomerType
        };
    }
}
