import { IBaseDesign } from '@profis-engineering/pe-ui-common/entities/design';
import {
    DesignExternalMetaData,
    DocumentAccessMode,
    DocumentServiceBase
} from '@profis-engineering/pe-ui-common/services/document.common';
import { ProjectDesignBaseEntity } from '../generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign';
import { IDetailedDisplayDesign } from '../entities/display-design';

/**
 * SignalRService base
 */
export abstract class DocumentServicePeBase extends DocumentServiceBase {

    public abstract openDesignExclusivePe(design: IBaseDesign): Promise<ProjectDesignBaseEntity>;
    public abstract updateDesignWithNewContent(designId: string, projectId: string, designName: string, contentOverride: ProjectDesignBaseEntity, metadataOverride: DesignExternalMetaData, displayDesign?: IDetailedDisplayDesign, unlock?: boolean, exclusiveLock?: boolean, documentAccessMode?: DocumentAccessMode): Promise<void>;
    public abstract openDesign(design: IBaseDesign): Promise<ProjectDesignBaseEntity>;
    public abstract createUniqueName(oldName: string, usedNames: string[]): string;
}
