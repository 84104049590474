import cloneDeep from 'lodash-es/cloneDeep';
import debounce from 'lodash-es/debounce';
import sortBy from 'lodash-es/sortBy';

import { Injectable } from '@angular/core';
import { Vector2 } from '@babylonjs/core/Maths/math.vector';
import { IAnchorPoint } from '@profis-engineering/pe-gl-model/components/anchor';
import { IDesignStateBase } from '@profis-engineering/pe-ui-common/entities/design';
import { ICheckboxProps } from '@profis-engineering/pe-ui-common/entities/main-menu/checkbox-props';
import { IControlProps } from '@profis-engineering/pe-ui-common/entities/main-menu/control-props';
import { IMainMenuControl, IMenu, MenuType } from '@profis-engineering/pe-ui-common/entities/main-menu/menu';
import { BaseControl, CheckBox, CustomControl, Menu, ToggleButtonGroup } from '@profis-engineering/pe-ui-common/entities/main-menu/navigation';
import { ITextBoxProps } from '@profis-engineering/pe-ui-common/entities/main-menu/textbox-props';
import { IToggleButtonGroupProps } from '@profis-engineering/pe-ui-common/entities/main-menu/toggle-button-group-props';
import { IModalOpened } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import {
    IMenu2dServiceExtensions, Menu2dServiceInjected
} from '@profis-engineering/pe-ui-common/services/menu-2d.common';
import { IDefineAnchorsValues } from '../../shared/components/define-anchors';
import { DesignPe } from '../../shared/entities/design-pe';
import { MainMenuModal } from '../../shared/entities/main-menu';
import { UIProperty } from '../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display';
import { CustomSteelMaterial } from '../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign';
import { PropertyMetaData } from '../../shared/properties/properties';
import { Controls2dEditor as Controls2dEditorPe, IMenu2dContext } from '../../shared/services/menu-2d.service.base';
import { ModalService } from './modal.service';
import { NavigationService } from './navigation.service';
import { NumberService } from './number.service';
import { UnitService } from './unit.service';
import { UserSettingsService } from './user-settings.service';
import { UserService } from './user.service';
import { IPointsTableProps, PointsTable } from '../components/main-menu/points-table/PointsTable';
import {
    createPointsTable2d, onChangeAddPointTable, onChangePointsTableCell, onCommitAddPointTable, onCommitPointTable,
    onDeletePointTable, updatePointsTable
} from '../helpers/main-menu-helpers/points-table';
import { PointsTableType } from '../../shared/generated-modules/Hilti.PE.Core.Entities.Navigation';

@Injectable({
    providedIn: 'root'
})
export class Menu2dService extends Menu2dServiceInjected implements IMenu2dServiceExtensions<IMenu2dContext> {
    private selectedCustomLayoutId?: number;
    private customAnchorLayoutData?: IDefineAnchorsValues;

    constructor(
        private modalService: ModalService,
        private navigationService: NavigationService,
        private numberService: NumberService,
        private unitService: UnitService,
        private userService: UserService,
        private userSettingsService: UserSettingsService
    ) {
        super();

        this.saveUserSettings = debounce(this.saveUserSettings.bind(this), 2000);
    }

    private get design() {
        return this.userService.design;
    }

    private get isAnchorEditable() {
        return !(this.design.properties.get(UIProperty.AnchorLayout_LayoutCustom).disabled || this.design.properties.get(UIProperty.AnchorLayout_LayoutCustom).hidden);
    }

    private get isPlateEditable() {
        return !(this.design.properties.get(UIProperty.AnchorPlate_PlateShapeCustom).disabled || this.design.properties.get(UIProperty.AnchorPlate_PlateShapeCustom).hidden) && (this.design.model[UIProperty.AnchorPlate_CustomLayoutPoints] as number[]).length > 2;
    }

    public initialize() {
        this.selectedCustomLayoutId = undefined;
        this.customAnchorLayoutData = undefined;

        this.setMenuExtensions({} as IMenu2dServiceExtensions<IMenu2dContext>);
        this.menuExtensions.getMenuStructure = this.getMenuStructure.bind(this);
        this.menuExtensions.getMenuCommands = this.getMenuCommands.bind(this);
        this.menuExtensions.getMenuModals = this.getMenuModals.bind(this);

        this.menuExtensions.getMenuControls = this.getMenuControls.bind(this);
        this.menuExtensions.updateMenuControlsProps = this.updateMenuControlsProps.bind(this);
        this.menuExtensions.onMenuStateChanged = this.onMenuStateChanged.bind(this);
        this.menuExtensions.onMenuAllowedValuesChanged = this.onMenuAllowedValuesChanged.bind(this);

        this.menuExtensions.getSelectedTab = this.getSelectedTab.bind(this);

        this.menuExtensions.createCheckbox = this.createCheckbox.bind(this);
        this.menuExtensions.createTextBox = this.createTextBox.bind(this);
        this.menuExtensions.createToggleButtonGroup = this.createToggleButtonGroup.bind(this);
        this.menuExtensions.createCustomControl = this.createCustomControl.bind(this);
    }

    // Data
    public getMenuStructure(): Menu<BaseControl<string>, string> | undefined {
        const menu = this.navigationService.initialize().Menus.find((menu) => menu.Id == MenuType.Menu2D);
        if (menu != null) {
            return cloneDeep(menu);
        }

        return undefined;
    }

    public getMenuCommands(): Record<string, (navigationControl: BaseControl<string>) => void> {
        return {
            ['OpenDefineAnchorsPopup']: () => { /* Nothing to do. */ }
        };
    }

    public getMenuModals(): Record<number, (input?: object | undefined) => IModalOpened> {
        return {
            [MainMenuModal.selectProfile]: (input?: object) => this.modalService.openSelectProfile(input),
            [MainMenuModal.profileSize]: (input?: object) => this.modalService.openProfileSize(input),
            [MainMenuModal.baseplateSize]: (input?: object) => this.modalService.openBaseplateSize(input),
            [MainMenuModal.brickSize]: (input?: object) => this.modalService.openBrickSize(input),
            [MainMenuModal.onSiteTests]: () => this.modalService.openOnSiteTests(),
            [MainMenuModal.selectMaterial]: (input?: object) => this.modalService.openSelectMaterial(input),
        };
    }

    // Controls
    public getMenuControls(
        design: DesignPe,
        state: IDesignStateBase,
        menu: IMenu,
        getControlName: (name: string) => string,
        getControlByName: (menu: IMenu, name: string) => IControlProps
    ): { [id: string]: IControlProps } {
        return {
            [getControlName(Controls2dEditorPe.StandardLayout)]: { activeValue: design.model[UIProperty.AnchorLayout_Layout], hidden: !this.isAnchorEditable } as any,
            [getControlName(Controls2dEditorPe.Baseplate)]: { activeValue: design.model[UIProperty.AnchorPlate_PlateShape] } as any,
            [getControlName(Controls2dEditorPe.LedgerAngle)]: { activeValue: design.model[UIProperty.AnchorPlate_LedgerAngleShape] } as any,
            [getControlName(Controls2dEditorPe.AnchorNodes)]: { isEditable: this.isAnchorEditable } as any,
            [getControlName(Controls2dEditorPe.BaseplateNodes)]: { isEditable: this.isPlateEditable, hidden: (design.model[UIProperty.AnchorPlate_CustomLayoutPoints] as number[]).length < 1 } as any,
            [getControlName(Controls2dEditorPe.CoordinateSystemCenter)]: { checkedValue: design.model[UIProperty.Option_CoordinateSystemCenter] } as any,
            [getControlName(Controls2dEditorPe.GridSpacing)]: { value: this.unitService.formatUnitAsDefault((getControlByName(menu, getControlName(Controls2dEditorPe.GridSpacing)) as ITextBoxProps).value ?? '', UnitGroup.Length) } as any,

            [getControlName(Controls2dEditorPe.StiffenerLayout)]: { selectedValue: design.model[UIProperty.AnchorPlate_StiffenerLayout], hidden: design.properties.get(UIProperty.AnchorPlate_StiffenerLayout).hidden } as any,
            [getControlName(Controls2dEditorPe.StiffenerRadialOffset)]: { selectedValue: design.model[UIProperty.AnchorPlate_StiffenerRadialOffset], hidden: design.properties.get(UIProperty.AnchorPlate_StiffenerRadialOffset).hidden } as any,
            [getControlName(Controls2dEditorPe.StiffenerWeldLegLength)]: {
                value: this.unitService.formatInternalValueAsDefault(design.model[UIProperty.AnchorPlate_StiffenerWeldLegLength] as number, UnitGroup.Length),
                disabled: design.properties.get(UIProperty.AnchorPlate_StiffenerWeldLegLength).disabled,
                hidden: design.properties.get(UIProperty.AnchorPlate_StiffenerWeldLegLength).hidden
            } as any,
            [getControlName(Controls2dEditorPe.StiffenerMaterial)]: {
                isCustomSteelSelected: state.model[PropertyMetaData.AnchorPlate_IsStiffenerMaterialCustom.id] as boolean,
                customMaterialName: state.model[PropertyMetaData.AnchorPlate_IsStiffenerMaterialCustom.id] as boolean ? (state.model[PropertyMetaData.AnchorPlate_CustomStiffenerMaterial.id] as CustomSteelMaterial).Name : '',
                selectedSteelTypeId: design.model[UIProperty.AnchorPlate_StiffenerMaterial],
                hidden: design.properties.get(UIProperty.AnchorPlate_StiffenerMaterial).hidden
            } as any,
            [getControlName(Controls2dEditorPe.StiffenerShape)]: { selectedValue: design.model[UIProperty.AnchorPlate_StiffenerShape], hidden: design.properties.get(UIProperty.AnchorPlate_StiffenerShape).hidden } as any,
            [getControlName(Controls2dEditorPe.StiffenerThickness)]: {
                value: this.unitService.formatInternalValueAsDefault(design.model[UIProperty.AnchorPlate_StiffenerThickness] as number, UnitGroup.Length),
                hidden: design.properties.get(UIProperty.AnchorPlate_StiffenerThickness).hidden
            } as any,

            [getControlName(Controls2dEditorPe.StiffenerWidth)]: {
                value: this.unitService.formatInternalValueAsDefault(design.model[UIProperty.AnchorPlate_StiffenerWidth] as number, UnitGroup.Length),
                hidden: design.properties.get(UIProperty.AnchorPlate_StiffenerWidth).hidden
            } as any,
            [getControlName(Controls2dEditorPe.StiffenerHeight)]: {
                value: this.unitService.formatInternalValueAsDefault(design.model[UIProperty.AnchorPlate_StiffenerHeight] as number, UnitGroup.Length),
                hidden: design.properties.get(UIProperty.AnchorPlate_StiffenerHeight).hidden
            } as any,
            [getControlName(Controls2dEditorPe.StiffenerHorizontalEdgeLength)]: {
                value: this.unitService.formatInternalValueAsDefault(design.model[UIProperty.AnchorPlate_StiffenerHorizontalEdgeLength] as number, UnitGroup.Length),
                hidden: design.properties.get(UIProperty.AnchorPlate_StiffenerHorizontalEdgeLength).hidden
            } as any,
            [getControlName(Controls2dEditorPe.StiffenerVerticalEdgeLength)]: {
                value: this.unitService.formatInternalValueAsDefault(design.model[UIProperty.AnchorPlate_StiffenerVerticalEdgeLength] as number, UnitGroup.Length),
                hidden: design.properties.get(UIProperty.AnchorPlate_StiffenerVerticalEdgeLength).hidden
            } as any,
            [getControlName(Controls2dEditorPe.StiffenerWeldMaterial)]: { selectedSteelTypeId: design.model[UIProperty.AnchorPlate_StiffenerWeldMaterial], hidden: design.properties.get(UIProperty.AnchorPlate_StiffenerWeldMaterial).hidden } as any,
            [getControlName(Controls2dEditorPe.StiffenerWeldThickness)]: {
                value: this.unitService.formatInternalValueAsDefault(design.model[UIProperty.AnchorPlate_StiffenerWeldThickness] as number, UnitGroup.Length),
                hidden: design.properties.get(UIProperty.AnchorPlate_StiffenerWeldThickness).hidden,
                disabled: design.properties.get(UIProperty.AnchorPlate_StiffenerWeldThickness).disabled
            } as any,
            [getControlName(Controls2dEditorPe.StiffenerWeldLocation)]: { activeValue: design.model[UIProperty.AnchorPlate_StiffenerWeldLocation], hidden: design.properties.get(UIProperty.AnchorPlate_StiffenerWeldLocation).hidden } as any,

            [getControlName(Controls2dEditorPe.StiffenerMaterialUltimateStrength)]: {
                value: this.unitService.formatInternalValueAsDefault(design.model[UIProperty.AnchorPlate_StiffenerMaterialUltimateStrength] as number, UnitGroup.Stress),
                hidden: design.properties.get(UIProperty.AnchorPlate_StiffenerMaterialUltimateStrength).hidden,
                disabled: design.properties.get(UIProperty.AnchorPlate_StiffenerMaterialUltimateStrength).disabled
            } as any,
            [getControlName(Controls2dEditorPe.StiffenerMaterialYieldStrength)]: {
                value: this.unitService.formatInternalValueAsDefault(design.model[UIProperty.AnchorPlate_StiffenerMaterialYieldStrength] as number, UnitGroup.Stress),
                hidden: design.properties.get(UIProperty.AnchorPlate_StiffenerMaterialYieldStrength).hidden,
                disabled: design.properties.get(UIProperty.AnchorPlate_StiffenerMaterialYieldStrength).disabled
            } as any,
            [getControlName(Controls2dEditorPe.StiffenerMaterialEModulus)]: {
                value: this.unitService.formatInternalValueAsDefault(design.model[UIProperty.AnchorPlate_StiffenerMaterialEModulus] as number, UnitGroup.Stress),
                hidden: design.properties.get(UIProperty.AnchorPlate_StiffenerMaterialEModulus).hidden,
                disabled: design.properties.get(UIProperty.AnchorPlate_StiffenerMaterialEModulus).disabled
            } as any,
            [getControlName(Controls2dEditorPe.StiffenerMaterialDensity)]: {
                value: this.unitService.formatInternalValueAsDefault(design.model[UIProperty.AnchorPlate_StiffenerMaterialDensity] as number, UnitGroup.Density),
                hidden: design.properties.get(UIProperty.AnchorPlate_StiffenerMaterialDensity).hidden,
                disabled: design.properties.get(UIProperty.AnchorPlate_StiffenerMaterialDensity).disabled
            } as any,
            [getControlName(Controls2dEditorPe.StiffenerMaterialPoisson)]: { value: design.model[UIProperty.AnchorPlate_StiffenerMaterialPoisson], hidden: design.properties.get(UIProperty.AnchorPlate_StiffenerMaterialPoisson).hidden, disabled: design.properties.get(UIProperty.AnchorPlate_StiffenerMaterialPoisson).disabled } as any,
            [getControlName(Controls2dEditorPe.StiffenerWeldMaterialUltimateStrength)]: {
                value: this.unitService.formatInternalValueAsDefault(design.model[UIProperty.AnchorPlate_StiffenerWeldMaterialUltimateStrength] as number, UnitGroup.Stress),
                hidden: design.properties.get(UIProperty.AnchorPlate_StiffenerWeldMaterialUltimateStrength).hidden
            } as any,
            [getControlName(Controls2dEditorPe.StiffenerWeldMaterialYieldStrength)]: {
                value: this.unitService.formatInternalValueAsDefault(design.model[UIProperty.AnchorPlate_StiffenerWeldMaterialYieldStrength] as number, UnitGroup.Stress),
                hidden: design.properties.get(UIProperty.AnchorPlate_StiffenerWeldMaterialYieldStrength).hidden
            } as any,
            [getControlName(Controls2dEditorPe.StiffenerWeldMaterialEModulus)]: {
                value: this.unitService.formatInternalValueAsDefault(design.model[UIProperty.AnchorPlate_StiffenerWeldMaterialEModulus] as number, UnitGroup.Stress),
                hidden: design.properties.get(UIProperty.AnchorPlate_StiffenerWeldMaterialEModulus).hidden
            } as any,
            [getControlName(Controls2dEditorPe.StiffenerWeldMaterialDensity)]: {
                value: this.unitService.formatInternalValueAsDefault(design.model[UIProperty.AnchorPlate_StiffenerWeldMaterialDensity] as number, UnitGroup.Density),
                hidden: design.properties.get(UIProperty.AnchorPlate_StiffenerWeldMaterialDensity).hidden
            } as any,
            [getControlName(Controls2dEditorPe.StiffenerWeldMaterialPoisson)]: { value: design.model[UIProperty.AnchorPlate_StiffenerWeldMaterialPoisson], hidden: design.properties.get(UIProperty.AnchorPlate_StiffenerWeldMaterialPoisson).hidden } as any,
            [getControlName(Controls2dEditorPe.StiffenerWeldMaterialBetaW)]: { value: design.model[UIProperty.AnchorPlate_StiffenerWeldMaterialBetaW], hidden: design.properties.get(UIProperty.AnchorPlate_StiffenerWeldMaterialBetaW).hidden } as any,
            [getControlName(Controls2dEditorPe.StiffenerWeldGravity)]: { value: design.model[UIProperty.AnchorPlate_StiffenerWeldGravity], hidden: design.properties.get(UIProperty.AnchorPlate_StiffenerWeldGravity).hidden } as any,
        };
    }

    public updateMenuControlsProps() {
        /* Nothing to do. */
    }

    public onMenuStateChanged(context: IMenu2dContext) {
        this.updatePointsTable(context, PointsTableType.Anchor);
        this.updatePointsTable(context, PointsTableType.Plate);
    }

    public onMenuAllowedValuesChanged() {
        /* Nothing to do. */
    }

    // Tab
    public getSelectedTab(): string | undefined {
        return 'tab-Edit2dTab';
    }

    public createCheckbox(context: IMenu2dContext, navControl: CheckBox<string>, control: IMainMenuControl<IDesignStateBase, DesignPe>, checkBoxProps: ICheckboxProps): void {
        if (navControl.Name == Controls2dEditorPe.SnapToGrid) {
            checkBoxProps.checked = this.userSettingsService.settings.application.controls.snapToGrid.value == null || this.userSettingsService.settings.application.controls.snapToGrid.value;
            checkBoxProps.checkedChanged = this.onChangeSnapToGrid.bind(this, context);
        }
    }

    public createTextBox() {
        /* Nothing to do. */
    }

    public createToggleButtonGroup(context: IMenu2dContext, navControl: ToggleButtonGroup<string>, control: IMainMenuControl<IDesignStateBase, DesignPe>, toggleButtonGroupProps: IToggleButtonGroupProps): void {
        const glModelPeComponent = context.glModelComponent;

        switch (navControl.Name) {
            case Controls2dEditorPe.StandardLayout: {
                toggleButtonGroupProps.activeValue = (this.design.model[UIProperty.AnchorLayout_Layout] as number);
                toggleButtonGroupProps.valueChanged = this.onChangeStandardLayout.bind(this, context);
                toggleButtonGroupProps.hidden = !this.isAnchorEditable;

                if (navControl.SecondaryCodelistName != null && navControl.SecondaryCodelistName != '') {
                    toggleButtonGroupProps.items = (toggleButtonGroupProps.itemsCollapsed ?? []).slice(0, 4);
                    toggleButtonGroupProps.itemsCollapsed = undefined;
                }
                break;
            }
            case Controls2dEditorPe.CustomLayout:
                toggleButtonGroupProps.valueChanged = this.onChangeCustomLayout.bind(this, context);
                toggleButtonGroupProps.hidden = !this.isAnchorEditable;

                toggleButtonGroupProps.items?.forEach(item => item.clicked = this.onClickCustomLayout.bind(this, context));
                break;
            case Controls2dEditorPe.Baseplate: {
                toggleButtonGroupProps.activeValue = (this.design.model[UIProperty.AnchorPlate_PlateShape] as number);
                toggleButtonGroupProps.valueChanged = this.onChangeBaseplate.bind(this, context);

                // TODO: is this really necessary?
                const allowedValues = this.design.properties.get(UIProperty.AnchorPlate_PlateShape).allowedValues;
                toggleButtonGroupProps.items = toggleButtonGroupProps.initialItems?.filter((item) => allowedValues != null ? allowedValues.some((allowed) => allowed == item.value) : true);

                glModelPeComponent.update({ plate: { editable: this.isPlateEditable } });
                break;
            }

            case Controls2dEditorPe.LedgerAngle: {
                toggleButtonGroupProps.activeValue = (this.design.model[UIProperty.AnchorPlate_LedgerAngleShape] as number);
                toggleButtonGroupProps.valueChanged = this.onChangeLedgerAngleShape.bind(this, context);

                const allowedshapes = this.design.properties.get(UIProperty.AnchorPlate_LedgerAngleShape).allowedValues;
                toggleButtonGroupProps.items = toggleButtonGroupProps.initialItems?.filter((item) => allowedshapes != null ? allowedshapes.some((allowed) => allowed == item.value) : true);

                glModelPeComponent.update({ plate: { editable: this.isPlateEditable } });
                break;
            }
        }
    }

    public createCustomControl(context: IMenu2dContext, navControl: CustomControl<IControlProps, string>, _control: IMainMenuControl, customControlProps: IControlProps) {
        if(customControlProps.type == PointsTable){
            const pointsTableProps = (customControlProps as IPointsTableProps);
            const type = createPointsTable2d(this.design, context.glModelComponent, navControl.Name, customControlProps);

            if (type != null) {
                pointsTableProps.tableType = type;
                pointsTableProps.canInsert = true;
                pointsTableProps.hidden = (pointsTableProps?.items?.length ?? 0) < 1;
                pointsTableProps.valueChanged = onChangePointsTableCell.bind(this, context, type);
                pointsTableProps.onCommit = onCommitPointTable.bind(this, this.design, context, type, this.unitService);
                pointsTableProps.onDelete = onDeletePointTable.bind(this, context, type);
                pointsTableProps.addInputChanged = onChangeAddPointTable.bind(this, context, type);
                pointsTableProps.addInputCommit = onCommitAddPointTable.bind(this, this.unitService, this.design, context, type);
            }
        }
    }

    public updatePointsTable(context: IMenu2dContext, pointsTableProps: PointsTableType){
        updatePointsTable(context, pointsTableProps);
    }

    // Helpers
    private saveUserSettings() {
        this.userSettingsService.save();
    }

    private onChangeSnapToGrid(
        context: IMenu2dContext,
        checked: boolean
    ) {
        context.setState(menu => this.updateMainMenuControl<ICheckboxProps>(menu, this.controlName(Controls2dEditorPe.SnapToGrid) ?? '', { checked } as any) ?? {} as IMenu);

        context.glModelComponent.update({
            grid2d: {
                snapToGrid: checked
            }
        });

        this.userSettingsService.settings.application.controls.snapToGrid.value = checked;
        this.saveUserSettings();
    }

    private onChangeStandardLayout(
        context: IMenu2dContext,
        value: number | number[]
    ) {
        context.setState(menu => this.updateMainMenuControl(menu, this.controlName(Controls2dEditorPe.StandardLayout) ?? '', { activeValue: value } as any) ?? {} as IMenu);

        context.glModelComponent.clearSelected();

        this.design.model[UIProperty.AnchorLayout_Layout] = value;
        context.save2dState({}, true);
    }

    private onChangeCustomLayout(
        context: IMenu2dContext,
        value: number | number[]
    ) {
        const standardLayoutControl = this.getControlByName(context.currentMenu(), this.controlName(Controls2dEditorPe.StandardLayout) ?? '') as IToggleButtonGroupProps;

        this.selectedCustomLayoutId = (value as number);

        if (standardLayoutControl.activeValue != null) {
            context.setState(menu => this.updateMainMenuControl(menu, this.controlName(Controls2dEditorPe.StandardLayout) ?? '', { activeValue: null } as any) ?? {} as IMenu);
        }
    }

    private onClickCustomLayout(context: IMenu2dContext) {
        const props = {
            values: this.customAnchorLayoutData,
            activeValue: () => this.selectedCustomLayoutId,
            onChange: (data: IDefineAnchorsValues) => {
                this.onAnchorPointsChange(context, data);
            }
        };
        this.modalService.openDefineAnchors(props);
    }

    private onChangeBaseplate(
        context: IMenu2dContext,
        value: number | number[]
    ) {
        context.setState(menu => this.updateMainMenuControl(menu, this.controlName(Controls2dEditorPe.Baseplate) ?? '', { activeValue: value } as any) ?? {} as IMenu);

        context.glModelComponent.clearSelected();
        this.design.model[UIProperty.AnchorPlate_PlateShape] = value;
        context.save2dState({}, true);
    }

    private onChangeLedgerAngleShape(
        context: IMenu2dContext,
        value: number | number[]
    ) {
        context.setState(menu => this.updateMainMenuControl(menu, this.controlName(Controls2dEditorPe.LedgerAngle) ?? '', { activeValue: value } as any) ?? {} as IMenu);

        context.glModelComponent.clearSelected();
        this.design.model[UIProperty.AnchorPlate_LedgerAngleShape] = value;
        context.save2dState({}, true);
    }

    private onAnchorPointsChange(
        context: IMenu2dContext,
        data: IDefineAnchorsValues
    ) {
        if (data == undefined) {
            return;
        }

        this.customAnchorLayoutData = data;

        const anchors: { id: number; x: number; y: number }[] = [];

        const width = (data.numberOfAnchors.x - 1) * data.spacingOfAnchors.x;
        let height = (data.numberOfAnchors.y - 1) * data.spacingOfAnchors.y;

        let id = 0;
        const glModelPeComponent = context.glModelComponent;

        if (this.selectedCustomLayoutId == 1 || (this.selectedCustomLayoutId == 2 && (data.numberOfAnchors.x < 3 || data.numberOfAnchors.y < 3))) {
            for (let i = 0; i < data.numberOfAnchors.x * data.numberOfAnchors.y; i++) {
                anchors.push({
                    id: ++id,
                    x: (-width / 2) + (width / (data.numberOfAnchors.x - 1)) * (i % data.numberOfAnchors.x) || 0,
                    // tslint:disable-next-line:no-bitwise
                    y: (-height / 2) + (height / (data.numberOfAnchors.y - 1)) * (~~(i / data.numberOfAnchors.x)) || 0
                });
            }
        }
        else if (this.selectedCustomLayoutId == 2 || this.selectedCustomLayoutId == 3) {
            const model = glModelPeComponent?.getModel();
            const idNum = (this.selectedCustomLayoutId == 2) ? ((data.numberOfAnchors.x * (data.numberOfAnchors.y - 1)) - 1) : (data.numberOfAnchors.y * 2 + data.numberOfAnchors.x);

            height = this.selectedCustomLayoutId == 2 ? height : (model.plate?.lengthB ?? 0) - data.edgeOfAnchors.y * 2;

            for (let i = 0; i < data.numberOfAnchors.x; i++) {
                const xPos = (-width / 2) + (width / (data.numberOfAnchors.x - 1)) * (i % data.numberOfAnchors.x) || 0;

                anchors.push({
                    id: ++id,
                    x: xPos,
                    y: -height / 2
                }, {
                    id: id + idNum,
                    x: xPos,
                    y: height / 2
                });
            }

            const width2 = (this.selectedCustomLayoutId == 2) ? width : (model.plate?.lengthA ?? 0) - data.edgeOfAnchors.x * 2;
            const layoutHeight = (this.selectedCustomLayoutId == 2) ? data.numberOfAnchors.y - 2 : data.numberOfAnchors.y;
            const numberOfAnchors = (this.selectedCustomLayoutId == 2) ? data.numberOfAnchors.y - 1 : data.numberOfAnchors.y + 1;

            height = numberOfAnchors * data.spacingOfAnchors.y;

            for (let i = 0; i < layoutHeight; i++) {
                const yPos = (-height / 2) + (height / numberOfAnchors) * (i + 1) || 0;

                anchors.push({
                    id: ++id,
                    x: -width2 / 2,
                    y: yPos
                }, {
                    id: ++id,
                    x: width2 / 2,
                    y: yPos
                });
            }
        }
        else if (this.selectedCustomLayoutId == 4) {
            for (let i = 0; i < data.numberOfAnchors.x; i++) {
                anchors.push({
                    id: ++id,
                    x: data.spacingOfAnchors.x * Math.cos(2 / data.numberOfAnchors.x * i * Math.PI),
                    y: data.spacingOfAnchors.x * Math.sin(2 / data.numberOfAnchors.x * i * Math.PI)
                });
            }
        }

        glModelPeComponent.update({
            anchor: {
                points: sortBy(anchors, item => item.id).map((item): IAnchorPoint => ({
                    // We round to 2 because thats how precise user can place anchors in custom layout, and we want to avoid cos/sin floating problem.
                    position: new Vector2(this.numberService.round(item.x, 2), this.numberService.round(item.y, 2))
                }))
            }
        });

        glModelPeComponent.clearSelected();
        glModelPeComponent.resetRotation({ anchor: true });

        context.save2dState({ [UIProperty.AnchorLayout_CustomLayoutPoints]: true }, false);
    }
}
