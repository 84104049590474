import { Injectable } from '@angular/core';
import { Design, IBaseDesign } from '@profis-engineering/pe-ui-common/entities/design';
import { Project } from '@profis-engineering/pe-ui-common/entities/project';
import {
    DesignExternalMetaData,
    DocumentAccessMode, IDesignListItem
} from '@profis-engineering/pe-ui-common/services/document.common';

import {
    ProjectDesignBaseEntity
} from '../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign';
import { DocumentServicePeBase } from '../../shared/services/document.service.base';
import { IDetailedDisplayDesign } from '@profis-engineering/pe-ui-common/entities/display-design';

@Injectable({
    providedIn: 'root'
})
export class DocumentService extends DocumentServicePeBase {
    private baseService!: DocumentServicePeBase;

    public setBaseService(baseService: DocumentServicePeBase) {
        this.baseService = baseService;
        this.findDesignById = baseService.findDesignById.bind(baseService);
        this.openDesignExclusivePe = baseService.openDesignExclusivePe.bind(baseService);
        this.findProjectByDesignId = baseService.findProjectByDesignId.bind(baseService);
        this.uploadDesignImage = baseService.uploadDesignImage.bind(baseService);
        this.findProjectById = baseService.findProjectById.bind(baseService);
        this.addDesignCommon = baseService.addDesignCommon.bind(baseService);
        this.updateDesignWithNewContentCommon = baseService.updateDesignWithNewContentCommon.bind(baseService);
        this.updateDesignThumbnailImage = baseService.updateDesignThumbnailImage.bind(baseService);
        this.publish = baseService.publish.bind(baseService);
        this.copyDesign = baseService.copyDesign.bind(baseService);
        this.moveDesign = baseService.moveDesign.bind(baseService);
        this.openDesignExclusive = baseService.openDesignExclusive.bind(baseService);
        this.getSessionKeyForDesign = baseService.getSessionKeyForDesign.bind(baseService);
        this.updateDesignWithNewContent = baseService.updateDesignWithNewContent.bind(baseService);
        this.openDesign = baseService.openDesign.bind(baseService);
        this.createUniqueName = baseService.createUniqueName.bind(baseService);
    }

    public get projects() {
        return this.baseService.projects;
    }

    public get draftsProject() {
        return this.baseService.draftsProject;
    }

    /* eslint-disable @typescript-eslint/no-unused-vars */
    public createUniqueName(_oldName: string, _usedNames: string[]): string {
        return '';
    }

    public openDesign(_design: IBaseDesign): Promise<ProjectDesignBaseEntity>{
        return {} as Promise<ProjectDesignBaseEntity>;
    }

    public findDesignById(_designId: string): IDesignListItem {
        return {} as IDesignListItem;
    }

    public openDesignExclusivePe(_design: IBaseDesign): Promise<ProjectDesignBaseEntity> {
        return {} as Promise<ProjectDesignBaseEntity>;
    }

    public findProjectByDesignId(_designId: string) {
        return {} as Project;
    }

    public uploadDesignImage(_designId: string, _imageContent: string, _thumbnailContent: string): Promise<void> {
        return {} as Promise<void>;
    }

    public updateDesignWithNewContent(_designId: string, _projectId: string, _designName: string, _contentOverride: ProjectDesignBaseEntity, _metadataOverride: DesignExternalMetaData, _displayDesign?: IDetailedDisplayDesign, _unlock?: boolean, _exclusiveLock?: boolean, _documentAccessMode?: DocumentAccessMode): Promise<void>{
        return {} as Promise<void>;
    }

    public findProjectById(_projectId: string): Project {
        throw new Error('Method not implemented.');
    }

    public addDesignCommon(_projectId: string, _design: Design, _canGenerateUniqueName: boolean, _ignoreConflict: boolean): Promise<IDesignListItem> {
        throw new Error('Method not implemented.');
    }

    public updateDesignWithNewContentCommon(design: Design<unknown>, displayDesign: unknown, unlock: boolean, exclusiveLock: boolean, documentAccessMode?: DocumentAccessMode): Promise<void> {
        throw new Error('Method not implemented.');
    }

    public updateDesignThumbnailImage(designId: string, thumbnailContent: string, respond: boolean): Promise<void> {
        throw new Error('Method not implemented.');
    }

    public publish(id: string): Promise<void> {
        throw new Error('Method not implemented.');
    }

    public copyDesign(documentId: string, documentName: string, projectId: string): Promise<void> {
        throw new Error('Method not implemented.');
    }

    public moveDesign(documentId: string, projectId: string): Promise<void> {
        throw new Error('Method not implemented.');
    }

    public openDesignExclusive<TProjectDesign>(_design: IBaseDesign, _adjustContent?: (content: TProjectDesign, designName: string, projectName: string) => TProjectDesign): Promise<TProjectDesign> {
        throw new Error('Method not implemented.');
    }

    public getSessionKeyForDesign(_designId: string): string {
        throw new Error('Method not implemented.');
    }
    /* eslint-enable @typescript-eslint/no-unused-vars */
}
