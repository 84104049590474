import { Change } from '@profis-engineering/pe-ui-common/services/changes.common';
import { UserServiceBase } from '@profis-engineering/pe-ui-common/services/user.common';
import { AsadCalculateScopeCheckError, AsadOptimizeCase, AsadOptimizeMessage, AsadOutput, AsadPoint, DesignPe } from '../entities/design-pe';
import { CalculateScopeCheckError, OptimizeCaseDetailedProgress, OptimizeCaseDetails, OptimizeDesignProgress, OptimizeMessage } from '../entities/signalr';
import { UIProperty } from '../generated-modules/Hilti.PE.Core.Entities.Baseplate.Display';
import { Point2DEntity } from '../generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign';
import { EmbedmentDepthOptimizationType } from '../generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import { CalculationServiceBasePE } from '../services/calculation.common';

export interface ModelChangesData {
    embedmentDepth: number;
    anchorCoordinates: AsadPoint[];
    anchorPlateWidth: number;
    anchorPlateHeight: number;
    profileEccentricityX: number;
    profileEccentricityY: number;
    fillHoles: boolean;
    isEdgeXNegativeReinforced: boolean;
    isEdgeXPositiveReinforced: boolean;
    isEdgeYNegativeReinforced: boolean;
    isEdgeYPositiveReinforced: boolean;
    baseMaterialEdgeXNegative?: number;
    baseMaterialEdgeXPositive?: number;
    baseMaterialEdgeYNegative?: number;
    baseMaterialEdgeYPositive?: number;
    fastenerId: number;
    variableEmbedmentDepth: boolean;
}

export class ModelChanges {
    private changes: Change[] = [];

    constructor(
        private userService: UserServiceBase<DesignPe>,
        private calculationService: CalculationServiceBasePE
    ) { }

    public get hasChanges() {
        return this.changes.length > 0;
    }

    public addValue(property: UIProperty, value: unknown) {
        if (value != null) {
            this.changes.push({
                name: property.toString(),
                oldValue: this.userService.design.model[property],
                newValue: value
            });
        }
    }

    public async calculate() {
        if (this.hasChanges) {
            await this.calculationService.calculateAsync(this.userService.design, undefined, {
                changes: this.changes
            });
        }
    }
}

export class AsadMapper {
    public mapOptimizeDesignProgress(output?: AsadOutput, optimizeDesignProgress?: OptimizeDesignProgress) {
        if (!output || !optimizeDesignProgress) {
            return;
        }

        // general
        this.optimizeGeneral(output, optimizeDesignProgress);

        // optimize case
        if (optimizeDesignProgress.OptimizeCaseProgresses != null) {
            for (const optimizeCase of optimizeDesignProgress.OptimizeCaseProgresses) {
                this.optimizeSingleCase(output, optimizeCase);
            }
        }

        // general info
        this.optimizeGeneralInfo(output, optimizeDesignProgress);
    }

    public mapOptimizeMessage(optimizeMessage: OptimizeMessage): AsadOptimizeMessage {
        return {
            id: optimizeMessage.Id,
            message: optimizeMessage.Message,
            severity: optimizeMessage.Severity
        };
    }

    public mapCalculateScopeCheckError(scopeCheckError: CalculateScopeCheckError, optimizeCaseDetails?: OptimizeCaseDetails): AsadCalculateScopeCheckError {
        return {
            fastenerId: scopeCheckError.FastenerId,
            layoutNumber: optimizeCaseDetails?.LayoutNumber ?? 0,
            numberOfAnchors: optimizeCaseDetails?.NumberOfAnchors ?? 0,
            anchorCoordinates: scopeCheckError.AnchorCoordinates.map((p: any) => ({ x: p.X, y: p.Y })),
            anchorPlateHeight: scopeCheckError.AnchorPlateHeight,
            anchorPlateWidth: scopeCheckError.AnchorPlateWidth,
            baseMaterialEdgeXNegative: scopeCheckError.BaseMaterialEdgeXNegative,
            baseMaterialEdgeXPositive: scopeCheckError.BaseMaterialEdgeXPositive,
            baseMaterialEdgeYNegative: scopeCheckError.BaseMaterialEdgeYNegative,
            baseMaterialEdgeYPositive: scopeCheckError.BaseMaterialEdgeYPositive,
            calculationCount: scopeCheckError.CalculationCount,
            embedmentDepth: scopeCheckError.EmbedmentDepth,
            fillHoles: scopeCheckError.FillHoles,
            isEdgeXNegativeReinforced: scopeCheckError.IsEdgeXNegativeReinforced,
            isEdgeXPositiveReinforced: scopeCheckError.IsEdgeXPositiveReinforced,
            isEdgeYNegativeReinforced: scopeCheckError.IsEdgeYNegativeReinforced,
            isEdgeYPositiveReinforced: scopeCheckError.IsEdgeYPositiveReinforced,
            profileEccentricityX: scopeCheckError.ProfileEccentricityX,
            profileEccentricityY: scopeCheckError.ProfileEccentricityY,
            scopeChecks: scopeCheckError.ScopeChecks
        };
    }

    public calculateMaxUtilization(utilizations: Record<string, number>) {
        return Math.max(...[
            0,
            utilizations['Tension.Decisive'],
            utilizations['Shear.Decisive'],
            utilizations['Combination.Decisive'],
        ].filter(x => x != null));
    }

    public createModelChanges(calculationService: CalculationServiceBasePE, userService: UserServiceBase<DesignPe>, data: ModelChangesData) {
        const modelChanges = new ModelChanges(userService, calculationService);

        modelChanges.addValue(UIProperty.AnchorLayout_EmbedmentDepthOptimizationType, EmbedmentDepthOptimizationType.UserSelected);
        modelChanges.addValue(UIProperty.AnchorLayout_Fastener, data.fastenerId);

        if (data.fillHoles != null) {
            modelChanges.addValue(UIProperty.AnchorLayout_FillHolesETAG, data.fillHoles);
        }

        if (data.embedmentDepth != null && data.variableEmbedmentDepth) {
            modelChanges.addValue(UIProperty.AnchorLayout_EmbedmentDepthVariable, data.embedmentDepth);
        }

        modelChanges.addValue(UIProperty.AnchorLayout_CustomLayoutPoints, data.anchorCoordinates.map((x): Point2DEntity => ({
            X: x.x,
            Y: x.y
        })));
        modelChanges.addValue(UIProperty.AnchorPlate_Width, data.anchorPlateWidth);
        modelChanges.addValue(UIProperty.AnchorPlate_Height, data.anchorPlateHeight);

        modelChanges.addValue(UIProperty.Profile_OffsetX, data.profileEccentricityX);
        modelChanges.addValue(UIProperty.Profile_OffsetY, data.profileEccentricityY);

        // base material edge distance
        modelChanges.addValue(UIProperty.BaseMaterial_IsEdgeXNegativeReinforced, data.isEdgeXNegativeReinforced);
        modelChanges.addValue(UIProperty.BaseMaterial_IsEdgeXPositiveReinforced, data.isEdgeXPositiveReinforced);
        modelChanges.addValue(UIProperty.BaseMaterial_IsEdgeYNegativeReinforced, data.isEdgeYNegativeReinforced);
        modelChanges.addValue(UIProperty.BaseMaterial_IsEdgeYPositiveReinforced, data.isEdgeYPositiveReinforced);

        if (data.baseMaterialEdgeXNegative != null) {
            const edgeXNegativeFromAnchor = data.baseMaterialEdgeXNegative - (-1 * Math.min(...data.anchorCoordinates.map(x => x.x)));

            modelChanges.addValue(UIProperty.BaseMaterial_EdgeXNegativeFromAnchor, edgeXNegativeFromAnchor);
        }

        if (data.baseMaterialEdgeXPositive != null) {
            const edgeXPositiveFromAnchor = data.baseMaterialEdgeXPositive - Math.max(...data.anchorCoordinates.map(x => x.x));

            modelChanges.addValue(UIProperty.BaseMaterial_EdgeXPositiveFromAnchor, edgeXPositiveFromAnchor);
        }

        if (data.baseMaterialEdgeYNegative != null) {
            const edgeYNegativeFromAnchor = data.baseMaterialEdgeYNegative - (-1 * Math.min(...data.anchorCoordinates.map(x => x.y)));

            modelChanges.addValue(UIProperty.BaseMaterial_EdgeYNegativeFromAnchor, edgeYNegativeFromAnchor);
        }

        if (data.baseMaterialEdgeYPositive != null) {
            const edgeYPositiveFromAnchor = data.baseMaterialEdgeYPositive - Math.max(...data.anchorCoordinates.map(x => x.y));

            modelChanges.addValue(UIProperty.BaseMaterial_EdgeYPositiveFromAnchor, edgeYPositiveFromAnchor);
        }

        return modelChanges;
    }


    private optimizeGeneral(output: AsadOutput, optimizeDesignProgress: OptimizeDesignProgress) {

        if (optimizeDesignProgress.CalculationCount != null) {
            output.calculationCount = optimizeDesignProgress.CalculationCount;
        }
        else {
            output.calculationCount = output.calculationCount ?? 0;
        }

        if (optimizeDesignProgress.Logs != null) {
            output.logs += optimizeDesignProgress.Logs.join('\n');
        }

        if (optimizeDesignProgress.ScopeCheckErrors != null) {
            for (const scopeCheckError of optimizeDesignProgress.ScopeCheckErrors) {
                output.scopeCheckErrors.push(this.mapCalculateScopeCheckError(scopeCheckError));
            }
        }

        if (optimizeDesignProgress.OptimizeMessages != null) {
            for (const optimizeMessage of optimizeDesignProgress.OptimizeMessages) {
                output.optimizeMessages.push(this.mapOptimizeMessage(optimizeMessage));
            }
        }
    }

    private optimizeSingleCase(output: AsadOutput, optimizeCase: OptimizeCaseDetailedProgress) {
        let outputOptimizeCase: AsadOptimizeCase = output.optimizeCases[optimizeCase.OptimizeCaseId as any];
        if (outputOptimizeCase == null) {
            outputOptimizeCase = {
                optimizeCaseId: optimizeCase.OptimizeCaseId,
                optimizeCaseDetails: undefined,
                calculationCount: 0,
                logs: ''
            };
            output.optimizeCases[optimizeCase.OptimizeCaseId as any] = outputOptimizeCase;
        }

        // only update if we have CalculationCount and is greater than the current calculationCount
        if (optimizeCase.CalculationCount != null && (outputOptimizeCase.calculationCount == null || outputOptimizeCase.calculationCount < optimizeCase.CalculationCount)) {
            outputOptimizeCase.calculationCount = optimizeCase.CalculationCount;
        }

        if (optimizeCase.Logs != null) {
            outputOptimizeCase.logs += optimizeCase.Logs.join('\n');
        }

        if (optimizeCase.ScopeCheckErrors != null) {
            for (const scopeCheckError of optimizeCase.ScopeCheckErrors) {
                output.scopeCheckErrors.push(this.mapCalculateScopeCheckError(scopeCheckError, optimizeCase.OptimizeCaseDetails));
            }
        }

        if (optimizeCase.OptimizeMessages != null) {
            for (const optimizeMessage of optimizeCase.OptimizeMessages) {
                output.optimizeMessages.push(this.mapOptimizeMessage(optimizeMessage));
            }
        }

        if (optimizeCase.OptimizeCaseDetails != null) {
            const optimizeCaseDetails = optimizeCase.OptimizeCaseDetails;

            outputOptimizeCase.optimizeCaseDetails = {
                optimizeCaseIndex: optimizeCaseDetails.OptimizeCaseIndex,
                fastenerId: optimizeCaseDetails.FastenerId,
                layoutNumber: optimizeCaseDetails.LayoutNumber,
                numberOfAnchors: optimizeCaseDetails.NumberOfAnchors,
                fastenerFamilyId: optimizeCaseDetails.FastenerFamilyId,
                isAtToolAllowed: optimizeCaseDetails.IsAtToolAllowed,
                isAutoCleaningAllowed: optimizeCaseDetails.IsAutoCleaningAllowed,
                engineeringValue: optimizeCaseDetails.EngineeringValue,
                partialFamilyId: optimizeCaseDetails.PartialFamilyId,
                variableEmbedmentDepth: optimizeCaseDetails.VariableEmbedmentDepth,
                corrosionMaterial: optimizeCaseDetails.CorrosionMaterial
            };
        }

        this.optimizeCaseOutput(optimizeCase, outputOptimizeCase);
    }

    private optimizeCaseOutput(optimizeCase: OptimizeCaseDetailedProgress, outputOptimizeCase: AsadOptimizeCase) {
        if (optimizeCase.OptimizeCaseOutput != null) {
            const optimizeCaseOutput = optimizeCase.OptimizeCaseOutput;

            outputOptimizeCase.optimizeCaseOutput = {
                anchorCoordinates: optimizeCaseOutput.AnchorCoordinates.map((p: any) => ({ x: p.X, y: p.Y })),
                anchorPlateHeight: optimizeCaseOutput.AnchorPlateHeight,
                anchorPlateWidth: optimizeCaseOutput.AnchorPlateWidth,
                area: optimizeCaseOutput.Area,
                constraintEvaluationsCount: optimizeCaseOutput.ConstraintEvaluationsCount,
                embedmentDepth: optimizeCaseOutput.EmbedmentDepth,
                exitFlag: optimizeCaseOutput.ExitFlag,
                fillHoles: optimizeCaseOutput.FillHoles,
                calculationTime: optimizeCaseOutput.CalculationTime,
                maxUtilization: this.calculateMaxUtilization(optimizeCaseOutput.Utilizations),
                profileEccentricityX: optimizeCaseOutput.ProfileEccentricityX,
                profileEccentricityY: optimizeCaseOutput.ProfileEccentricityY,
                utilizations: optimizeCaseOutput.Utilizations,
                isEdgeXNegativeReinforced: optimizeCaseOutput.IsEdgeXNegativeReinforced,
                isEdgeXPositiveReinforced: optimizeCaseOutput.IsEdgeXPositiveReinforced,
                isEdgeYNegativeReinforced: optimizeCaseOutput.IsEdgeYNegativeReinforced,
                isEdgeYPositiveReinforced: optimizeCaseOutput.IsEdgeYPositiveReinforced,
                baseMaterialEdgeXNegative: optimizeCaseOutput.IsEdgeXNegativeReinforced === false
                    ? optimizeCaseOutput.BaseMaterialEdgeXNegative : undefined,
                baseMaterialEdgeXPositive: optimizeCaseOutput.IsEdgeXPositiveReinforced === false
                    ? optimizeCaseOutput.BaseMaterialEdgeXPositive : undefined,
                baseMaterialEdgeYNegative: optimizeCaseOutput.IsEdgeYNegativeReinforced === false
                    ? optimizeCaseOutput.BaseMaterialEdgeYNegative : undefined,
                baseMaterialEdgeYPositive: optimizeCaseOutput.IsEdgeYPositiveReinforced === false
                    ? optimizeCaseOutput.BaseMaterialEdgeYPositive : undefined,
                searchAlgorithm: optimizeCaseOutput.SearchAlgorithm as number,
                TCO: optimizeCaseOutput.TCO,
                BaseplateCosts: optimizeCaseOutput.BaseplateCosts,
                AnchorsCosts: optimizeCaseOutput.AnchorsCosts,
                InstallationCosts: optimizeCaseOutput.InstallationCosts
            };
        }
    }

    private optimizeGeneralInfo(output: AsadOutput, optimizeDesignProgress: OptimizeDesignProgress) {
        const optimizeCases = Object.values(output.optimizeCases);

        output.totalCalculationCount = output.calculationCount +
            optimizeCases.reduce((a, b) => a + b.calculationCount, 0);

        output.totalCasesCount = optimizeCases.length;

        for (const optimizeCase of optimizeDesignProgress.OptimizeCaseProgresses ?? []) {
            if (optimizeCase.OptimizeCaseOutput == null) {
                continue;
            }
            output.doneCasesCount++;
            if (optimizeCase.OptimizeCaseOutput.ExitFlag == null) {
                continue;
            }

            if (optimizeCase.OptimizeCaseOutput.ExitFlag >= 0) {
                output.feasibleCasesCount++;
            }
            else {
                output.errorCasesCount++;
            }
        }

        if (optimizeDesignProgress.MaxOptimizeCasesCount != null) {
            output.maxOptimizeCasesCount = optimizeDesignProgress.MaxOptimizeCasesCount;
        }

        if (optimizeDesignProgress.RunningOptimizationsCount != null) {
            output.runningOptimizationsCount = optimizeDesignProgress.RunningOptimizationsCount;
        }
        else {
            output.runningOptimizationsCount = output.runningOptimizationsCount ?? 0;
        }
    }
}
