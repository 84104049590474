import { Injectable } from '@angular/core';
import { FeatureFlagFromPeUi, FeaturesVisibilityServiceInjected } from '@profis-engineering/pe-ui-common/services/feature-visibility.common';

export type FeatureFlag = FeatureFlagFromPeUi;

@Injectable({
  providedIn: 'root'
})
export class FeaturesVisibilityService extends FeaturesVisibilityServiceInjected<FeatureFlag> {
}
