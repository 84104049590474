import {
    EnvironmentHelper
} from '@profis-engineering/pe-ui-common/helpers/environment-helper';
import {
    QueryStringHelper
} from '@profis-engineering/pe-ui-common/helpers/query-string-helper';

import type schema from './environment.local.json';

export type Environment = typeof schema & {
    baseUrl: string;
    debugGlModel: boolean;
    asadQuery: boolean;
    supportedFeatures: string[];
};

export const environment: Environment = getEnvironmentSettings();
const qsHelper = new QueryStringHelper(window.location.search);

environment.supportedFeatures = ['loadingInitialData'];

// setBaseUrl
environment.baseUrl = document.getElementsByTagName('base')[0].href;

// set debug
environment.debugGlModel = environment.enableDebugGlModelQuery && qsHelper.getBoolean('debug');

// setAsadQuery
environment.asadQuery = qsHelper.getBoolean('asad');

function getEnvironmentSettings(): Environment {
    const helper = new EnvironmentHelper(window.location.host);
    const [serviceVersion, version] = helper.getServiceVersions();

    const env: Environment = (window as any).environmentPe;
    if (serviceVersion) {
        env.baseplateCADWebServiceUrl = helper.formatServiceVersion(env.baseplateCADWebServiceUrl, serviceVersion);
        env.baseplateApplicationWebServiceUrl = helper.formatServiceVersion(env.baseplateApplicationWebServiceUrl, serviceVersion);
        env.baseplateCalculationWebServiceUrl = helper.formatServiceVersion(env.baseplateCalculationWebServiceUrl, serviceVersion);
        env.trackingWebServiceUrl = helper.formatServiceVersion(env.trackingWebServiceUrl, serviceVersion);
        env.signalRServerUrl = helper.formatServiceVersion(env.signalRServerUrl, serviceVersion);
        env.signalRCoreServerUrl = helper.formatServiceVersion(env.signalRCoreServerUrl, serviceVersion);

        env.asadServiceUrl = helper.formatServiceVersion(env.asadServiceUrl, serviceVersion);
        env.asadSignalRServerUrl = helper.formatServiceVersion(env.asadSignalRServerUrl, serviceVersion);
        env.asadSignalRCoreServerUrl = helper.formatServiceVersion(env.asadSignalRCoreServerUrl, serviceVersion);

        env.baseplateApplicationWebServiceUiApiUrl = helper.formatVersion(env.baseplateApplicationWebServiceUiApiUrl, version);
    }
    return env ?? {};
}
